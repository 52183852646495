##
#   Limit characters on element
#   @param {object} element - jQuery element
#   @param {int} length - maximum length allowed
#   @param {object} infoElement - element with information
#   @format {string} format string - '{typed} / {left} '
##
window.limit_chars_on_textarea =  (element, length, infoElement, format = '{typed} / {left}') ->

  element.keyup ->
    text = $(this).val()
    if text.length >= length
      text = text.substr(0, length)
      $(this).val text
    if infoElement
      updateInfo(infoElement, length, text.length, format)
    return

  element.keydown (event) ->
    text = $(this).val()
    if text.length >= length
      return event.keyCode == 8 or event.keyCode == 46 or event.keyCode == 37 or event.keyCode == 38 or event.keyCode == 39 or event.keyCode == 40
    return
  return

updateInfo = (infoElement, length, typed, format = '{typed} / {left}' )->
  info = format.replace('{typed}', typed).replace('{left}', length - typed)
  infoElement.text info
  if typed >= length
    infoElement.addClass 'error'
  if typed < length
    infoElement.removeClass 'error'
  if typed < length-10 or typed >= length
    infoElement.removeClass 'warning'
  if typed >= length - 10 and typed < length
    infoElement.addClass 'warning'
