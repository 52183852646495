window.update_post_to_dealer_orders = (event, ui) ->
  content = ui.item.value
  regexped = content.match(/(\d+)\)/);
  if regexped
    id = regexped[1]
    $('#form_body_after_dealer').hide()
    $.get '/post_to_dealers/update_form_for_new_dealer', { dealer_id: id}, (data) ->
      $('#form_body_after_dealer').html(data)
      $('#form_body_after_dealer').show()

window.keep_updating_items_value = ->
  $(document).on 'change', '.order_checkbox, #post_to_dealer_delivery_method', ->
    stock_items_value = 0
    order_ids = ''
    $('.order_checkbox').each ->
      if $(@).is(':checked')
        stock_items_value += parseFloat($(@).data('stock-products-price'))
        order_ids += "#{$(@).val()} "
    $('#stock_items_value').html(fnumber(stock_items_value))

    delivery_method = $('#post_to_dealer_delivery_method').val()
    $.get '/post_to_dealers/update_transport_costs', {order_ids: order_ids, delivery_method: delivery_method}, (data) ->
      $('#transport_costs').html(data)
