buy_grams_timeout_handle = null;

update_buying_year_bonus = (element) ->
  tr = $(element).parents('tr')
  amount = tr.find('.buy_grams')
  if element.is(':checked')
    amount.val($(amount).data('fullAmount'))
    amount.removeAttr('readonly')
  else
    amount.val(0)
    amount.attr('readonly', true)


window.recalculate_bonus_grams_prices = ->
  $('.buy_grams').on 'keyup', ->
    if buy_grams_timeout_handle
      clearTimeout(buy_grams_timeout_handle)
    buy_grams_timeout_handle = setTimeout(update_all, 500)
  $('.buy_year_bonus').on 'change', ->
    update_buying_year_bonus($(this))
    update_all()
  $('.buy_year_bonus').each ->
    update_buying_year_bonus($(this))
  update_all();


price_of_grams = (element, price_field ) ->
  full_price = parseFloat(element.data(price_field))
  full_amount = parseFloat(element.data('fullAmount'))
  decimals = element.val().replace(',','.').split('.').reverse()[0].length || 0

  amount = parseFloat(element.val().replace(',','.'))
  if amount < 0 || isNaN(amount)
    amount = 0
  else if amount > full_amount
    amount = full_amount
    decimals = 5
  position = element[0].selectionStart
  element.val(amount.toFixed(Math.min(decimals, 5)).replace('.',','))
  element[0].selectionStart = position
  element[0].selectionEnd = position
  if full_amount == 0
    0
  else
    full_price * amount / full_amount

update_all = ->
  total = 0
  $('.buy_grams').each ->
    price = price_of_grams($(this), 'price')
    value = price_of_grams($(this), 'value')
    updateRow($(this), price, value)
    total += price
  updateTotal(total)

updateTotal = (total)->
  $('#total_credits').text(total.toFixed(2).replace('.',','))

updateRow = (element, price, value ) ->
  tr = element.parents('tr')
  tr.find('.credits').text(price.toFixed(2).replace('.',','))
  tr.find('.value').text(value.toFixed(2).replace('.',','))
  if tr.find('.exchange_rate')
    rate = parseFloat(tr.find('.exchange_rate').html())
    in_czk = value * rate
    tr.find('.value_czk').text(round(in_czk, 0))
