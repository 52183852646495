window.handover_queue_forms = ->
  $(document).on 'submit', '.handover_queue_form', (event) ->
    event.preventDefault();
    $(this).find('input[type="submit"]').prop('disabled', true)
    url = $(this).prop('action')
    tr = $(this).closest('tr')
    $.ajax
      url: url
      type: 'post'
      data: {}
      success: (data) ->
        tr.html(data)
        refresh_queue()
        refresh_next()

window.refresh_queue = ->
    waiting = $('#handover_queue_waiting').html()
    $.ajax
      url: "/handover_queue/refresh?ffc_id=#{handover_queue_ffc_id}&date=#{handover_queue_date}&waiting=#{waiting}"
      type: 'get'
      data: {}
      success: (data) ->
        $('#handover_queue').html(data)
      error: ->
        window.location.reload(true)

window.repeatedly_refresh_queue = ->
  setInterval(
    refresh_queue,
    10000)

window.refresh_next = ->
    $.ajax
      url: "/handover_queue/refresh_next?ffc_id=#{handover_queue_ffc_id}&date=#{handover_queue_date}"
      type: 'get'
      data: {}
      success: (data) ->
        $('#next_for_handover').html(data)
      error: ->
        window.location.reload(true)

window.repeatedly_refresh_next = ->
  setInterval(
    refresh_next,
    10000)

window.play_handover_queue_person_came_sound = ->
  audio = new Audio('/ding.wav')
  audio.play()

window.submit_ffc_for_handover_queue_change = ->
  $(document).on 'change', '#ffc_for_handover_queue', ->
    submit_form_of($(this))
