reload_timeout = null

update_location_after_credits_change = ->
  $('input').prop('disabled', 'disabled')
  params = new URLSearchParams(params)
  params.append('used_credits',  $('#used_credits').val())
  params.append('used_overcharge',  $('#used_overcharge').val())
  params.append('use_credits',  if $('#use_credits').is(':checked') then '1' else '0')
  params.append('use_overcharge', if $('#use_overcharge').is(':checked') then '1' else '0')
  params.append('charge_switzerland_storing_with_buyout', if $('#charge_switzerland_storing_with_buyout').is(':checked') then '1' else '0')
  $('#cart-form-wrapper').load '/cart_items?'+params.toString()+' #cart-form', ->
    $('input').removeProp('disabled')

window.cart_vouchers_update = ->
  $(document).on 'click', '#use_voucher', (event) ->
    vouchers = []
    $('.voucher_value').each (index, e) -> vouchers[index] = 'vouchers[]=' + $(e).val()
    if vouchers.length > 0
      document.location.href = '?' + vouchers.join('&')
    event.preventDefault()

window.activate_cart_add_voucher_field_link = ->
  $(document).on 'click', '#add_voucher_field_link', (event) ->
    new_input = $(this).parent().find('.voucher_value').last().clone()
    $(this).parent().find('.voucher_value').addClass('not_last_cart_voucher_value')
    new_input.val('')
    $(this).before('<div>')
    $(this).before(new_input)
    $(this).before('</div>')
    event.preventDefault()

window.update_use_overcharge_flag = ->
  $(document).on 'change', '#use_overcharge', (event) ->
    $('input').prop('disabled', 'disabled')
    update_location_after_credits_change()

window.update_use_credits = ->
  $(document).on 'change', '#use_credits, #charge_switzerland_storing_with_buyout', (event) ->
    $('input').prop('disabled', 'disabled')
    update_location_after_credits_change()

  $(document).on 'change', '#used_credits, #used_overcharge ', (event) ->
   if reload_timeout
    clearTimeout(reload_timeout)
    reload_timeout = setTimeout(update_location_after_credits_change, 1500)

  $(document).on 'keyup', '#used_credits, #used_overcharge', (event) ->
    if reload_timeout
      clearTimeout(reload_timeout)
    reload_timeout = setTimeout(update_location_after_credits_change, 1500)

window.refresh_after_cart_ffc_id_select = ->
  $(document).on 'change', '#cart_ffc_id #ffc_id', (event) ->
    document.location.href = '?ffc_id=' + $(this).val();

window.toggle_cart_item = ->
  $(document).on 'click', '.cart_item_check_box', (event) ->
    $('input').prop('disabled', 'disabled')
    document.location.href = "?toggle_cart_item_id=#{$(this).data('cart-item-id')}&checked=#{$(this).is(':checked')}";

window.fixed_cart_header = (element) ->
  fixmeTop = element.offset().top
  width = element.width()
  $(window).scroll ->
    currentScroll = $(window).scrollTop();
    if currentScroll >= fixmeTop
      element.css(
        position: 'fixed',
        top: '0',
        width: width+'px'
      )
    else
      element.css(
        position: 'static',
        width: 'auto'
      )

window.toggle_preorder = ->
  $(document).on 'change', '#advance_payment_allowed', (event) ->
     $('#preorder_row').toggle()
