window.empty_reminder_date = (select_field) ->
  $(select_field).parents('li').find('.date').val('')

window.show_sections = (select_field) ->
  if $(select_field).val() == 'on_day'
    $(select_field).parents('ol').find('.reminder_kind').hide()
    $(select_field).parents('ol').find('.reminder_date').show()
    $(select_field).parents('ol').find('.reminder_product').hide()
  else if $(select_field).val() == 'on_product_price'
    $(select_field).parents('ol').find('.reminder_kind').hide()
    $(select_field).parents('ol').find('.reminder_date').hide()
    $(select_field).parents('ol').find('.reminder_product').show()
