window.unassigned_clients = (acquire_text, validation_text) ->
  $('.acquire_client_select').change ->
    dealer_select = $(this).siblings('.dealer_select')
    not_interested_reason = $(this).siblings('.not_interested_reason')
    if $(this).val() == 'assign_under'
      dealer_select.show()
      not_interested_reason.hide()
    else if $(this).val() == 'not_interested'
      dealer_select.hide()
      not_interested_reason.show()
    else
      dealer_select.hide()
      not_interested_reason.hide()
      if $(this).val() == 'under_me' && !confirm(acquire_text)
        $(this).val('')


  $("#unassigned_clients_form").submit ->
    can_save = true
    $('.acquire_client_select').each (index, select) ->
      select = $(select)
      not_interested_reason = select.siblings('textarea.not_interested_reason')
      if select.val() == 'not_interested' && not_interested_reason.val().replace(/\s+/g, '') == ''
        alert(validation_text)
        can_save = false
    can_save

  $(document).on 'click', '.add_to_assign_clients_link', (event) ->
    event.preventDefault()
    client_id = $(this).data('client-id')
    div = $(this).parents('.add_to_assign_clients_div')
    $(this).hide()
    div.find('.spinner').show()
    $.ajax
      url: '/assign_clients'
      type: 'post'
      data: { client_id: client_id }
      success: (data) ->
        div.html(data)
      error: ->
        alert 'Chyba'

  $(document).on 'click', '.bulk_add_to_assign_clients_link', (event) ->
    event.preventDefault()
    client_ids = $(this).data('client-ids')
    div = $(this).parents('.bulk_add_to_assign_clients_div')
    $(this).hide()
    $('#bulk_add_spinner').show()
    $.ajax
      url: '/assign_clients/create_bulk'
      type: 'post'
      data: { client_ids: client_ids }
      success: (data) ->
         div.html(data)
       error: ->
         alert 'Chyba'
