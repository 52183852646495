update_post_to_client_shipping_attributes = ->
  order_ids = []
  stock_product_ids = ''
  $('.order_checkbox').each ->
    if $(@).is(':checked')
      order_id = $(@).val()
      order_ids.push(order_id)
      stock_product_ids = stock_product_ids + ',' + $("#stock_product_ids_#{order_id}").val()
  delivery_method = $('#post_to_client_delivery_method').val()
  $('#shipping_cost').html('')
  $('#shipment_value').html('')
  $('#shipment_weight').html('')
  $('#post_to_client_shipping_indicator').show()
  $('#post_to_client_shipment_value_indicator').show()
  $('#post_to_client_shipment_weight_indicator').show()
  $('#power_of_attorney_link').attr('href', $('#power_of_attorney_link').data('url') + "?stock_product_ids=#{stock_product_ids}")
  $.get '/post_to_clients/shipping_cost', {order_ids: order_ids.join(','), delivery_method: delivery_method}, (data) ->
    $('#post_to_client_shipping_indicator').hide()
    $('#shipping_cost').html(data)
  $.get '/post_to_clients/shipment_value.json', {order_ids: order_ids.join(','), delivery_method: delivery_method}, (data) ->
    $('#post_to_client_shipment_value_indicator').hide()
    $('#shipment_value').html(data['html_content'])
    $('#shipment_overvw_message').html(data['info_message'])
  $.get '/post_to_clients/shipment_weight.json', {order_ids: order_ids.join(','), delivery_method: delivery_method}, (data) ->
    $('#post_to_client_shipment_weight_indicator').hide()
    $('#shipment_weight').html(data['html_content'])
    $('#shipment_overvw_message').html(data['info_message'])

window.update_post_to_client_shipping = ->
  $(document).on 'change', '.order_checkbox, #post_to_client_delivery_method', ->
    update_post_to_client_shipping_attributes()

show_hide_post_by_agency_fields = (delivery)->
  if delivery == '4'
    $('#post_by_agency').show()
    $('#shipping_address_presenter').hide()
    $('#shipment_overvw_message').hide()
  else
    $('#post_by_agency').hide()
    $('#shipping_address_presenter').show()
    $('#shipment_overvw_message').show()

window.handle_post_to_client_delivery = ->
  $(document).on 'change', '#post_to_client_delivery_method', ->
    delivery = $(this).val()
    show_hide_post_by_agency_fields(delivery)
  show_hide_post_by_agency_fields($('#post_to_client_delivery_method').val())
