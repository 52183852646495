window.enable_load_by_contracts = ->
  $('.load_by_contracts').on 'click', (e)->
    e.preventDefault()
    el = $(this)
    target = el.siblings('.by_contracts')
    url = el.attr('href')
    target.html '<i class="fa fa-spinner"></i>'
    $.get url, {}, (data)->
      target.html(data)
      el.hide()
    false
