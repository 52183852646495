window.load_balance_items_detail = ->
  $(document).on 'click', '.item_breakdown_link', (event) ->
    event.preventDefault()
    $(this).hide()
    div = $(this).parents('.note')
    div.find('.indicator').show()
    url = $(this).data('url')
    $.get url, (data) ->
      div.html(data)

window.show_parsed_bank_statement_transactions = ->
  $(document).on 'click', '#show_bank_statement_transactions', (event) ->
    event.preventDefault()
    $(this).hide()
    div = $(this).parent()
    div.find('.indicator').show()
    url = $(this).data('url')
    $.get url, (data) ->
      div.html(data)
