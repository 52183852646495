window.update_commodity_transfer_percentages = ->
  $(document).on 'keyup', '.commodity_transfer_amount', (event) ->
    update_commodity_transfer_percentage($(this))
    update_commodity_transfer_price($(this))

window.update_commodity_transfer_percentage = (input) ->
    amount = parseFloat(input.val())
    total = get_client_buyout_total()
    if amount && total
      portion = if total == 0 then 0 else amount / total
      if portion > 1
        portion = 1
      percentage = "#{fnumber(Math.round(1000 * portion) / 10)} %"
      input.parents('tr').find('.commodity_transfer_percentage').html(percentage)
    update_commodity_transfer_left_to_pay()

window.update_commodity_transfer_left_to_pay = ->
  total = get_client_buyout_total()
  transferring = 0
  for input in $('.commodity_transfer_amount')
    if amount = parseFloat($(input).val())
      transferring += amount
  left_to_pay = round(total - transferring, 2)
  if left_to_pay < 0
    left_to_pay = 0
  $('#commodity_transfer_left_to_pay').html(fnumber(left_to_pay))

window.update_commodity_transfer_price = (input) ->
  grams_field = input.parents('tr').find('.commodity_transfer_grams')
  grams_field.html('')
  amount = parseFloat(input.val())
  product_id = input.data('product-id')
  currency = $('#client_buyout_currency').val()
  if amount > 0
    $.ajax
      url: "/products/#{product_id}.json"
      type: 'get'
      success: (data) ->
        price = parseFloat(data["price_#{currency}"])
        input.parents('tr').find('.commodity_transfer_price').val(price)
        weight = parseFloat(data['weight'])
        gram_price = price / weight
        grams = amount / gram_price
        grams = round(grams, 4)
        grams_field.html("#{fnumber(grams)} g")
