show_regions_on = ['non_recommenders']

$ ->
  for group in show_regions_on
    $("input[data-tag='#{group}']").change toggle_region_select

  $("input[data-tag='brokers']").change ->
    $("input[data-tag='selected_broker_company']").prop('disabled', @checked)

  $("input[data-tag='selected_broker_company']").change ->
    if @checked
      $("input[data-tag='brokers']").prop('disabled', true)
      $('#message_broker_company_id_wrapper').show()
    else
      $("input[data-tag='brokers']").prop('disabled', false)
      $('#message_broker_company_id_wrapper').hide()
  $("input[data-tag='event_date']").change ->
    if @checked
      $('#event_date_id_wrapper').show()
    else
      $('#event_date_id_wrapper').hide()

  $("input[data-tag='brokers']").change()
  $("input[data-tag='selected_broker_company']").change()
  $("input[data-tag='event_date']").change()
  toggle_region_select()


toggle_region_select = ->
  show = false
  for group in show_regions_on
    show = show || $("input[data-tag='#{group}']").is(':checked')
  if show
    $("#region-select").show()
  else
    $("#region-select").hide()
