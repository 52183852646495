recalculate_totals = (date) ->
  personal_points = parseFloat($("#personal_points_#{date}").val())
  if !personal_points
    personal_points = 0
  personal_commissions = parseFloat($("#personal_commissions_#{date}").html().replace(' ', ''))
  team_points = parseFloat($("#team_points_#{date}").val())
  if !team_points
    team_points = 0
  if indirect_commissions_value =  $("#indirect_commissions_#{date}").html()
    team_commissions = parseFloat(indirect_commissions_value.replace(' ', ''))
  else
    team_commissions = 0
  $("#total_points_#{date}").html(fnumber(personal_points + team_points))
  $("#total_commissions_#{date}").html(fnumber(personal_commissions + team_commissions))


recalculate_percentage = (field) ->
  planned_value = parseInt(field.val())
  team_value = parseInt(field.parents('td').find('.team_value').html().replace(/&nbsp;/g, '').replace(' ', ''))
  percentage_value_span = field.parents('td').find('.percentage')

  percentage_value = Math.round(if team_value == 0 then 0 else 100 * planned_value / team_value)
  if percentage_value < 10
    spaces = '&nbsp;&nbsp;'
  else if percentage_value < 100
    spaces = '&nbsp;'
  else
    spaces = ''
  percentage_value_span.html(spaces + fnumber(percentage_value))
  recalculate_totals(field.attr('id').replace(/team_.*_(.*)/, '$1'))


window.recalculate_values = ->
  $(document).on 'keyup', '.planned_value', ->
    recalculate_percentage($(this))

  $(document).on 'change', '.career, .personal_points', ->
    date = $(this).attr('id').replace('career_id_', '').replace('personal_points_', '')
    point_price = parseFloat($("#career_id_#{date}").find('option:selected').data('point-price'))
    points = $("#personal_points_#{date}").val()
    personal_commissions = points * point_price
    if !personal_commissions
      personal_commissions = 0
    $("#personal_commissions_#{date}").html(fnumber(personal_commissions))
    recalculate_totals(date)

