window.remove_fields = (link) ->
  link.prev("input[type=hidden]").val("1").trigger('change')
  link.closest(".fields").hide()

window.remove_fields_by_id = (link) ->
  link.prev("input[type=hidden]").val($(link).data('item-id')).trigger('change')
  link.closest(".fields").hide()

window.add_fields = (link, association, content, target, autocomplete_selectors, autocomplete_urls, select2_class) ->
  new_id = new Date().getTime()
  regexp = new RegExp("new_" + association, "g")
  new_item = $(content.replace(regexp, new_id))
  if !!autocomplete_selectors
    for selector, index in autocomplete_selectors
      $(selector, new_item).autocomplete({
        source: autocomplete_urls[index]
      })
  if target != null
    $(target).append(new_item)
  else
    $(link).parent().before(new_item)
  if !!select2_class
    $(".#{select2_class}").select2 theme: 'default', language: 'cs', width: 'resolve', dropdownAutoWidth: true

window.duplicate_field = (link, target, content) ->
  $(target).append(
    $(content).clone()
              .removeClass(
                content.replace('.', '').replace('#', '')
              )
  )
