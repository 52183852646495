import "@hotwired/turbo-rails"
Turbo.session.drive = false

require('intl')
require('url-search-params-polyfill')
require('appointment_details')
require('automatic_overcharge_transfers')
require('announcements')
require('articles')
require('company_bank_account_balances')
require('bitcoin_payments')
require('bonus_for_credits')
require('buyouts')
require('crypto_utils')
require('buyout_appointments')
require('cart')
require('client_buyouts')
require('client_interactions')
require('clients')
require('commissions')
require('commodity_transfers')
require('conference_ticket_reservations')
require('contracts')
require('contract_send_overcharge')
require('dashboards')
require('dealer_plans')
require('dealers')
require('dynamic_fields')
require('emails')
require('event_guest_vocative')
require('external_orders')
require('ffc_shipment_items')
require('ffc_shipments')
require('ffcs')
require('format_floats')
require('galleries')
require('hand_over_appointments')
require('hand_overs_and_shippings')
require('handover_queue')
require('leads')
require('market_news')
require('marketing_merchandise_orders')
require('no_contract_clients')
require('orders')
require('order_fixation')
require('pawns')
require('payments')
require('polls')
require('post_to_clients')
require('post_to_dealers')
require('price_watch_dogs')
require('products')
require('promo_codes')
require('reenable_buttons')
require('reminders')
require('reservations')
require('saving_radios')
require('selects')
require('settle_contract')
require('sms')
require('stock_reports')
require('stock')
require('storing_clients')
require('unassigned_clients')
require('user_checks')
require('announcements')
require('external_orders_bulk_rate_settings')
require('webinars')
require('eshop_order_payments')
require('monitored_background_jobs')
require('dealer_portal')
require('banners')
