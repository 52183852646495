window.update_event_guest_vocative_note = (element) ->
  placeholder = element.attr('placeholder')
  vocative = element.val()
  if (!!placeholder && !vocative)
    parent_div(element).find('span.vocative').show()
  else
    parent_div(element).find('span.vocative').hide()

window.suggest_event_guest_vocative = (element) ->
  name = parent_div(element).find('.event_guest_last_name').val()
  gender = parent_div(element).find('.event_guest_gender').val()
  if (!element.val() && !!name && !!gender)
    url = '/vocatives/suggest'
    params =
      name:  name
      gender: gender

    $.get url, params, (data, response) ->
      element.attr('placeholder', data['vocative'])
      update_event_guest_vocative_note(element)

window.parent_div = (element) ->
  $(element.parents('div')[0])

window.event_guest_vocative = ->
  $(document).on 'keypress', '.event_guest_vocative', ->
    update_event_guest_vocative_note($(this))

  $(document).on 'blur', '.event_guest_last_name', ->
    suggest_event_guest_vocative(parent_div($(this)).find('.event_guest_vocative'))

  $(document).on 'change', '.event_guest_gender', ->
    suggest_event_guest_vocative(parent_div($(this)).find('.event_guest_vocative'))
