window.update_seat_reservation = ->
  $(document).on 'click', 'a.conference_seat_number.ajax', (event) ->
    event.preventDefault()
    $(this).prop('disabled', true)
    reservation_id = $(this).data('reservation-id')
    action = $(this).data('action')
    if action == 'release' || action == 'reserve'
      action_name = action
    url = "/conference_ticket_reservations/#{reservation_id}/#{action_name}"
    target = $(this).parents('.reservation_td')
    $.ajax
      url: url
      type: 'patch'
      success: (data) ->
        target.html(data)
      error: ->
        alert 'Chyba'
