set_tabindexes = (selector) ->
  $(selector).each (index) ->
    $(this).attr('tabindex', index + 1)

window.set_tabindexes = set_tabindexes

sortables = (container, path, item_tag, axis, complete) ->
  $(document).ready ->
    $(container).sortable({
    axis: axis,
    dropOnEmpty: false,
    handle: '.handle',
    cursor: 'hand',
    items: item_tag,
    opacity: 0.4,
    scroll: true
    placeholder: 'alert',
    forcePlaceholderSize: true,
    update: ->
      if $(container).sortable('serialize')
        $.ajax({
        type: 'post',
        data: $(container).sortable('serialize'),
        dataType: 'script',
        url: path,
        complete: (request) ->
          set_tabindexes('.fields .tabindex')
          if complete
            complete()
        })
    })
    $("#{container} #{item_tag}").disableSelection()

window.sortables = sortables

refresh_photos = (id) ->
  $.ajax({
  dataType: 'json'
  url: '/galleries/' + id + '/processed',
  complete: (xhr) ->
    data = jQuery.parseJSON(xhr.responseText)
    $processing = $('.photo-medium-image a[data-processing=true]')
    $processing.each ->
      $self = $(this)
      key = 'photo_' + $self.data('id')
      if data && data[key]
        urls = data[key]
        $self.attr('href', urls['original'])
        $self.find('img').attr('src', urls['thumbnail'])
        $self.attr('data-processing', 'false')
    if $processing.length > 0
      setTimeout('refresh_photos(' + id + ')', 2000)
  })

window.refresh_photos = refresh_photos

window.set_warning_if_form_changed = (fields_selector, warning_text) ->
  set_warning = ->
    window.onbeforeunload = ->
      return warning_text

  $fields = $(fields_selector)
  if $fields.prop('tagName') == 'FORM'
    $form = $fields
  else
    $form = $fields.parents('form')
  $('input, textarea', $fields).change(set_warning)
  $('.remove_fields', $fields).click(set_warning)
  $form.submit ->
    window.onbeforeunload = null
    return true
