window.rewrite_stock_product_ids_in_url = ->
  $('.protocol').click ->
    form = $(this).parents('form')
    vals = []
    i = 0
    form.find('input[type="checkbox"]:checked').each (index, element) ->
      if $(element).attr('id')?.match(/stock_product/)
        vals[i] = $(element).val()
        i += 1
    action = $(this).attr('href')
    new_value = "stock_product_ids=#{vals.join(',')}"
    action = action.replace /stock_product_ids=[^&]*/, new_value
    document.location.href = action
    false

window.rewrite_stock_product_ids_protocol_form = ->
  $(document).on 'submit', '.protocol_form, .stock_preparation_form', (event) ->
    container = $(this).parents('.hand_over')
    if container.size() == 0
      container = $('.stock_products_form')
    vals = []
    i = 0
    container.find('input[type="checkbox"]:checked').each (index, element) ->
      if $(element).attr('id')?.match(/stock_product/)
        vals[i] = $(element).val()
        i += 1
    new_value = vals.join(',')
    input = $(this).find('input[name="stock_product_ids"]')[0] || $(this).find('input[class="stock_product_ids"]')[0]
    if new_value == ''
      alert('Není vybráno žádné zboží')
      event.preventDefault()
    else
      $(input).val(new_value)

window.display_stock_values = ->
  $('.display_stock_values_link').click (event) ->
    stock_group_id = $(this).data('stock-group-id')
    event.preventDefault()
    target_id = '#stocks_value_' + stock_group_id
    $(this).remove();
    $.ajax
      url: "/stocks_statistics/stock_values"
      type: "get"
      data: {stock_group_id: stock_group_id}
      success: (data) ->
        $(target_id).html(data)
      error: ->
        alert "Chyba"

window.add_button_id_to_request = ->
  $(document).on 'click', '.prepare_handover_protocol_button', (event) ->
    container = $(this).parents('form')
    input = container.find('input[class="button_id"]')
    input.val($(this).data('button-id'))

$ ->
  $('.product_suggest').autocomplete({
    source: '/products/suggest_name'
    select: (event, ui) ->
      if ui.item && id = ui.item.value.match(/\((\d+)\)/)
        $(event.target).val(ui.item.value)
        $(this).trigger('change')
  })
  $('.external_order_suggest').autocomplete({
    source: '/external_orders/suggest_number'
  })
  $('.buyout_invoice_suggest').autocomplete({
    source: '/buyout_invoices/suggest_number'
  })
  $('.wholesale_customer_suggest').autocomplete({
    source: '/wholesale_customers/suggest_name'
  })
  $('.wholesale_order_suggest').autocomplete({
    source: '/wholesale_orders/suggest_number'
  })

