show_or_hide_kind = ->
  if $('#reservation_day_of_week').val() == ''
    $('#kind-container').hide()
  else
    $('#kind-container').show()

show_or_hide_from_date = ->
  if $('#reservation_kind').val() == 'two_weeks'
    $('#from_date_container').show()
  else
    $('#from_date_container').hide()

reservations = ->
  $('.date').datepicker()
  $('#reservation_from_date').change ->
    $('#reservation_to_date').val($(this).val())
  $('#reservation_from_time_4i').change ->
    hour = $(this).val()
    hour = parseInt(hour.replace(/^0/, '')) + 1
    if hour < 10
      hour = '0' + hour
    $('#reservation_to_time_4i').val(hour)
  $('#reservation_from_time_5i').change ->
    $('#reservation_to_time_5i').val($(this).val())
  toggle_dates($("#reservation_day_of_week"))
  $('#reservation_day_of_week').change ->
    toggle_dates($("#reservation_day_of_week"))
  show_or_hide_kind()
  $('#reservation_day_of_week').change(show_or_hide_kind)
  show_or_hide_from_date()
  $('#reservation_kind').change(show_or_hide_from_date)

window.reservations = reservations
