window.load_fees = ->
   currency = $('#client_buyout_currency').val()
   amounts = []
   products = []
   for item in $('.buyout_amount')
     amounts.push($(item).val())
     products.push($(item).data('product-id'))
   amounts = amounts.join('|')
   products = products.join('|')
   if currency
     $.get "/client_buyouts/update_fee_charge?&currency=#{currency}&amounts=#{amounts}&products=#{products}", (data) ->
       $('#fee_charge').html(data)
       calculate_buyout_total()

window.update_client_buyout_prices = ->
  $(document).on 'keyup', '.buyout_amount, .buyout_grams', (event) ->
    calculate_buyout_total_after_item_change($(this))

  $(document).on 'change', '.buyout_amount', (event) ->
    load_fees()
  load_fees()

calculate_buyout_total = ->
  for item in $('.buyout_amount, .buyout_grams')
    calculate_buyout_total_after_item_change($(item))

calculate_buyout_total_after_item_change = (item) ->
  parent = item.parents('.client_buyout_product')
  product_price = parseFloat(parent.data('price'))
  product_vat = parseFloat(parent.data('vat'))
  weight = parseFloat(parent.data('weight'))

  buyout_amount = parent.find('.buyout_amount').val()
  if buyout_amount && buyout_amount != ''
    amount = parseInt(buyout_amount)
  else
    amount = 0
  maximum_amount = parseInt(parent.find('.buyout_amount').data('maximum'))
  if amount > maximum_amount
    parent.find('.buyout_amount').val(maximum_amount)
    amount = maximum_amount
  buyout_grams = parent.find('.buyout_grams').val()
  if buyout_grams && buyout_grams != ''
    grams = parseFloat(buyout_grams)
  else
    grams = 0
  maximum_grams = parseFloat(parent.find('.buyout_grams').data('maximum'))
  if grams > maximum_grams
    parent.find('.buyout_grams').val(maximum_grams)
    grams = maximum_grams
  price = round_price(product_price * amount + product_price * grams/weight)
  vat = round_price(product_vat * amount + product_vat * grams/weight)
  parent.find('.buyout_item_price').val(price)
  parent.find('.buyout_price_number').html(fnumber(price))
  parent.find('.buyout_item_vat_number').html(fnumber(vat))
  if price > 0
    parent.find('.buyout_price').show()
  else
    parent.find('.buyout_price').hide()
  if vat > 0
    parent.find('.buyout_item_vat').show()
  else
    parent.find('.buyout_item_vat').hide()

  total = 0

  $('.buyout_item_price').each (index, element) =>
    buyout_price_number = $(element).val()
    if buyout_price_number
      total += parseFloat(buyout_price_number)
  fee_charge = parseFloat($('#fee_charge_value').html())
  total = total - fee_charge
  precision = parseInt($('#buyout_rounding_precision').val())
  total = round(total, precision)
  total_vat = 0
  $('.buyout_item_vat_number').each (index, element) =>
    buyout_item_vat = $(element).html()
    if buyout_item_vat
      total_vat += parseFloat(buyout_item_vat.replace(/,/, '.').replace(' ', ''))
  total_vat = round(total_vat, precision)
  $('#buyout_total_number').html(fnumber(total))
  $('#buyout_total_vat_number').html(fnumber(total_vat))
  if total_vat > 0
    $('#vat_total').show()
  else
    $('#vat_total').hide()

  for input in $('.commodity_transfer_amount')
    update_commodity_transfer_percentage($(input))

window.get_client_buyout_total = ->
  parseFloat($('#buyout_total_number').html().replace(/,/, '.').replace(' ', ''))

window.enable_commodity_transfer_submit = ->
  $(document).on 'click', '#client_buyout_agreed_to_contract_and_terms', (event) ->
    if $(this).is(':checked')
      $('#confirm_client_buyout_button').prop('disabled', false)
    else
      $('#confirm_client_buyout_button').prop('disabled', true)

window.reload_if_currency_changes = ->
  $(document).on 'change', '#client_buyout_currency', (event) ->
    document.location = "?currency=#{$(this).val()}"

window.fill_all_for_buyout_item = ->
  $(document).on 'change', '.all', (event) ->
    if $(this).is(':checked')
      parent = $(this).parents('tr')
      parent.find('.buyout_amount').val(parent.find('.buyout_amount').data('maximum'))
      parent.find('.buyout_grams').val(parent.find('.buyout_grams').data('maximum'))
      calculate_buyout_total()
