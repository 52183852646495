window.products = ->
  $('.show_rest_of_product_description').click ->
    $(this).parent().find('.rest_of_product_description').show()
    $(this).parent().find('.show_rest_of_product_description_space').hide()
    $(this).hide()
    false

window.product_form = ->
  toggle_product_price_type_fields($('#product_price_type').val())
  $('.date').datepicker()
  $('#product_price_type').change ->
    toggle_product_price_type_fields($(this).val())
    update_price_type_product_attributes($(this).val())
  init_toggle('show_product_producer_name', true)
  init_toggle('show_product_issuer_name', true)
  $('#product_not_available').on 'change', ->
    $('#product_eshop_product_attributes_sold_out').prop('checked', $(this).is(':checked'))

toggle_product_price_type_fields = (price_type) ->
  hide_classes = []
  show_classes = []

  if price_type == 'fixed'
    hide_classes = ['.price_spot_based']
    show_classes = ['.price_fixed']
  else if price_type == 'spot_based'
    hide_classes = ['.price_fixed']
    show_classes = ['.price_spot_based']

  for css_klass in hide_classes
    $("#{css_klass} input").prop('disabled', true)
    $(css_klass).hide()
  for css_klass in show_classes
    $("#{css_klass} input").prop('disabled', false)
    $(css_klass).show()

update_price_type_product_attributes = (price_type) ->
  if price_type == 'fixed'
    $('#product_without_fixation').prop('checked', true)
  else if price_type == 'spot_based'
    $('#product_without_fixation').prop('checked', false)

window.price_list_currency_update = ->
  $('#price_list_currency').on 'change', ->
    $(this).prop('disabled', true)
    price_list_currency = $(this).val()
    search = $('#search').val()
    params = $(this).data('params')
    document.location.href = "?price_list_currency=#{price_list_currency}&#{params}"

window.price_list_products_availability_update = ->
  $('#products_availability').on 'change', ->
    $(this).prop('disabled', true)
    products_availability = $(this).val()
    params = $(this).data('params')
    document.location.href = "?products_availability=#{products_availability}&#{params}"

window.reload_in_person_sales = ->
  $(document).on 'change', '#ffc_for_in_person_sales', ->
    submit_form_of($(this))

window.update_product_after_discount_points = (product_id, currency) ->
  $(document).on 'keyup', '#product_discount', ->
      $.ajax
        url: "/promo_codes/product_discount_info"
        type: "get"
        data: {
          product_id: product_id
          amount: $('#product_discount').val()
          currency: currency
        }
        success: (data) ->
          $('.discount_info').html(data)

window.register_product_availability_tracking_form = ->

  $('.new_product_availability_tracking').on 'submit', ->
    form = $(this)
    form.find("input[type='submit']").replaceWith('<img src=\'/assets/spinner.gif\' />')
    $.ajax
      url: form.attr('action')
      type: "post"
      dataType : 'json'
      processData: true
      data: {
        product_availability_tracking: {
          product_id: form.find('.product_id').val()
        }
      }
      success: (data) ->
        form.after("<div class='note #{if data.success then 'notice' else 'error'}'>#{data.message}</div>")
        form.replaceWith(data.response)

    false
