recalculate_settlement_total = ->
  supplement_type = $('#settlement_type').val()
  if supplement_type == 'buyout_prices'
    total = parseFloat($('#buyout_total').html())
  else if supplement_type == 'saved'
    total = parseFloat($('#saved').html())
  else
    total = 0

  deduct = 0
  $('.deduct_order').each ->
    if $(@).is(':checked')
      deduct += parseFloat($(@).data('amount'))
  total -= deduct
  total = Math.round(total * 100) / 100
  $('#total').html(fnumber(total) + ' ' + $('#currency').html())

window.settlement_type_change = ->
  $('#settlement_type').change ->
    recalculate_settlement_total()
  recalculate_settlement_total()

window.settlement_deduct_order = ->
  $('.deduct_order').change ->
      recalculate_settlement_total()
