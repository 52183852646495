check_total_available = ->
  total_amount = parseFloat($('#total_amount').val()) || 0
  entered_amount = 0
  $('input[id^="contracts"]').each ->
    entered_amount += parseFloat(AutoNumeric.getAutoNumericElement('#'+$(@).attr('id')).get()) || 0

  if entered_amount > total_amount
    $('#amount_warning').show()
  else
    $('#amount_warning').hide()

  if entered_amount <= 0
    $('#zero_amount').show()
  else
    $('#zero_amount').hide()
  entered_amount <= total_amount && entered_amount > 0

check_split = ->
  split_mismatch = false
  $('input[id^="contracts"]').each ->
    contract_amount = parseFloat(AutoNumeric.getAutoNumericElement('#'+$(@).attr('id')).get()) || 0
    contract_id = $(@).data('contract-id')
    split_total = 0
    all_empty = true

    $(".split_for_contract_#{contract_id}").each ->
      if $(@).val() != '' && !all_empty
        all_empty = false
      split_total += parseFloat(AutoNumeric.getAutoNumericElement('#'+$(@).attr('id')).get()) || 0
    if !all_empty && contract_amount != split_total && !split_mismatch
      split_mismatch = true
  if split_mismatch
    $('#split_warning').show()
    false
  else
    $('#split_warning').hide()
    false

check = ->
  if check_total_available()
    $('#send_overcharge_form .submit').attr('disabled', false)
  else
    $('#send_overcharge_form .submit').attr('disabled', true)

update_read_state = (checkbox) ->
  element = $(checkbox).parents('.send_to_contract__wrapper')
  if checkbox.checked
    element.find('.send_to_contract').attr('readonly', true)
    element.find('.split_for_contract').attr('readonly', false)
  else
    element.find('.send_to_contract').attr('readonly', false)
    element.find('.split_for_contract').attr('readonly', true)

update_split = (wrapper) ->
  if !wrapper.find('.own_split').get(0) || wrapper.find('.own_split').get(0).checked
    return
  amount_element = wrapper.find('.send_to_contract')
  amount = parseFloat(AutoNumeric.getAutoNumericElement('#'+amount_element.attr('id')).get()) || 0
  split_elements = []
  wrapper.find('.split_for_contract').each ->
    if $(@).data('percent') > 0
      split_elements.push($(@))
    else
      $(@).val('')
  left_to_use = amount
  for e in split_elements
    if e == split_elements[split_elements.length-1]
      e.val(fnumber(left_to_use.toFixed(2)))
    else
      amount_to_use = (amount * parseInt(e.data('percent')) / 100.0).toFixed(2)
      left_to_use = left_to_use - amount_to_use
      e.val(fnumber(amount_to_use))

update_amount = (wrapper) ->
  if !wrapper.find('.own_split').get(0) || !wrapper.find('.own_split').get(0).checked
    return
  amount_element = wrapper.find('.send_to_contract')
  total = 0
  wrapper.find('.split_for_contract').each ->
    amount = parseFloat(AutoNumeric.getAutoNumericElement('#'+$(@).attr('id')).get()) || 0
    total += amount
  amount_element.val(fnumber(total.toFixed(2)))

jQuery ->
  $('#send_overcharge_form .send_to_contract').on 'change, keyup', ->
    update_split($(@).parents('.send_to_contract__wrapper'))
    check()

  $('#send_overcharge_form .split_for_contract').on 'change, keyup', ->
    update_amount($(@).parents('.send_to_contract__wrapper'))
    check()

  $('#send_overcharge_form').on 'submit', (event) ->
    if !check()
      return false
    true
  $('#submit-overcharge').on 'click', (event) ->
    if !check()
      return false
    true

  $('.own_split').on 'click, change', ->
    update_read_state(@)
    update_split($(@).parents('.send_to_contract__wrapper'))
    update_amount($(@).parents('.send_to_contract__wrapper'))
    check()

  $('.own_split').each -> update_read_state(@)
  $('#send_overcharge_form .send_to_contract__wrapper').each -> update_split($(@))
