window.promo_codes = ->
  $('.promo_code_kind_contract').click ->
    $('.promo-code-product-fields').remove()
    $('#add_promo_code_product_link').hide()
    $('.currency').hide()
    $('#discount_tables').hide()

  $('#promo_code_kind_one_off').click ->
    $('#add_promo_code_product_link').show()
    $('.currency').show()
    $('#discount_tables').show()

  $('#promo_code_currency').on 'change', ->
    update_currency_symbols()
    update_discount_tables()

  $('.promo_code_product_id').on 'change', ->
    update_product_discount_info($(this))

  $(document).on 'click', '.promo_code_kind', ->
    $('.promo_code_help').hide()
    $("##{$(this).val()}_help").show()
    if $(this).val() == 'investment_account_fee'
      $('#investment_account_percentage').show()
    else
      $('#investment_account_percentage').hide()
    if $(this).val() == 'investment_account_fixed_percentage'
      $('#investment_account_fixed_percentage').show()
    else
      $('#investment_account_fixed_percentage').hide()

  $(document).on 'keyup', '.promo_code_amount, .promo_code_points', ->
    product_id = $(this).parents('.fields').find('.promo_code_product_id')
    delay (->
      update_product_discount_info(product_id)
      return
    ), 1000
  $('.promo_code_kind:checked').click()

window.update_currency_symbols = ->
  val = $("#promo_code_currency option[value='" + $('#promo_code_currency').val() + "']").text()
  $('.currency_symbol').html(val)

window.update_discount_tables = ->
  $.ajax
    url: "/promo_codes/discount_tables"
    type: "get"
    data: {currency: $('#promo_code_currency').val()}
    success: (data) ->
      $('#discount_tables').html(data)

window.update_product_discount_info = (element) ->
  $.ajax
    url: "/promo_codes/product_discount_info"
    type: "get"
    data: {
      product_id: element.val()
      points_to_reduce: element.parents('.fields').find('.promo_code_points').val()
      amount: element.siblings('.promo_code_amount').val()
      currency: $('#promo_code_currency').val()
    }
    success: (data) ->
      element.parent().siblings('.discount_info').html(data)
