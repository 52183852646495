import 'intl'
import 'intl/locale-data/jsonp/cs'

update_external_orders_sum = ->
  values = {}
  $('.currency_row').hide()
  $('.currency_row input').removeAttr('required')
  update_conversion_rate_handling()

  $('.external_order_checkbox:checked').each ->
    el = $(this)
    currency = el.data('currency')
    total = parseFloat(el.data('total'))
    values[currency] ?= 0
    values[currency] += total
  rates = {}
  $('.rate').each ->
    if $(this).val()
      rates[$(this).data('currency')] = parseFloat($(this).val().replace(' ', '').replace(',', '.'))
  for currency, total of values
    $(".currency_#{currency}_total").text(format(total, currency))
    if rates[currency]
      value = format(total * rates[currency], 'CZK')
      $(".currency_#{currency}_total_czk").text("(#{value})")


format = (value, currency)->
  if Intl
    formatter = new Intl.NumberFormat('cs', {
      style: 'currency',
      currency: currency.toUpperCase(),
    });
    formatter.format(value)
  else
    "#{value.to_fixed(2)} #{currency.toUpperCase()}"


update_conversion_rate_handling = ->
  $('.external_order_checkbox:checked').each ->
    currency = $(@).data('currency')
    if $('#payment_type').val() in ['eur_rb', 'usd_rb', 'czk_rb', 'eur_tatra']
      $(".currency_#{currency}").show()
      $("#rate_handling_#{currency} option[value=keep_accounting_rate]").attr('selected', 'selected')
    else if $('#payment_type').val() != ''
      $(".currency_#{currency}").show()
      $("#rate_handling_#{currency} option[value=set_new_rate]").attr('selected', 'selected')
    update_conversion_rates()

update_conversion_rates = ->
  $('.rate_handling').each ->
    if $(@).val() == 'set_new_rate'
      $(@).parent().find(".rate").show()
      $(@).parent().find(".rate").attr('required', true)
    else if $(@).val() == 'keep_accounting_rate'
      $(@).parent().find(".rate").hide()
      $(@).parent().find(".rate").attr('required', false)

window.conversion_rate_settings_init = ->
  update_conversion_rate_handling()
  $('.external_order_checkbox, .rate').on 'change', ->
    update_external_orders_sum()
  $('#payment_type').on 'change', ->
    update_conversion_rate_handling()
  $('.rate_handling').on 'change', ->
    update_conversion_rates()
