window.fill_eshop_order_payment_variable_symbol = ->
  $(document).on 'change', '#payment_method, #ffc_id', ->
    vs_field = $('#variable_symbol')
    payment_method = $('#payment_method').val()
    ffc_id = $('#ffc_id').val()
    cointains_bullion = $('#cointains_bullion').val()
    submit = $(this).parents('form').find('.submit')
    if ffc_id != '' && payment_method == 'card'
      submit.attr('disabled', true)
      vs_field.show()
      vs_field.find('span').html('...')
      $.get '/cash_sales/variable_symbol', { cointains_bullion: cointains_bullion, ffc_id: ffc_id },
        (variable_symbol) ->
          vs_field.find('input').val(variable_symbol)
          vs_field.find('span').html(variable_symbol)
          submit.attr('disabled', false)
    else
      vs_field.hide()
      vs_field.find('input').val('')
      vs_field.find('span').html('')
