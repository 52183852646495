window.add_date_to_stock_report_links = ->
  $(document).on 'change', '.date_for_report', (_event) ->
    date = $(this).val()
    if date
      $(this).parents('tr').find('.stock_report_link').each ->
        report_link = $(@)
        report_href = report_link.attr('href')
        if report_href.match(/\?/)
          symbol = "&"
        else
          symbol = "?"
        report_link.attr('href', "#{report_href}#{symbol}date=#{date}")

      move_link = $(this).parents('tr').find('.add_to_stock_move')
      move_href = move_link.attr('href')
      if move_href.match(/\?/)
        symbol = "&"
      else
        symbol = "?"
      move_link.attr('href', "#{move_href}#{symbol}date=#{date}")
