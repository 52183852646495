savings_data_xhr = null

show_hide_dealer_contract_fields = ->
  fields = $('#dealer_legal_kind_3').parents('fieldset')
  if $('#dealer_legal_kind_3')?.is(':checked')
    fields.find('.person').hide()
    fields.find('.company').show()
  else if $('#dealer_legal_kind_2')?.is(':checked')
    fields.find('.company').hide()
    fields.find('.person').show()
    fields.find('.sole_trader').show()
  else if $('#dealer_legal_kind_1')?.is(':checked')
    fields.find('.company').hide()
    fields.find('.person').show()
  show_hide_is_vat_payer_fields()
  toggle_vat_identification_number()

show_hide_client_contract_fields = ->
  if $(' #no_contract_client_is_company_true').size() > 0
    field = $('#no_contract_client_is_company_true')
    fields = field.parents('form')
  else
    field = $('#client_is_company_true, #pawn_is_company_true, #buyout_appointment_is_company_true')
    fields = field.parents('fieldset')
  if field?.is(':checked')
    fields.find('.person').hide()
    fields.find('.company').show()
  else if field
    fields.find('.company').hide()
    fields.find('.person').show()
  else
    $('.company').hide()
    $('.person').show()


set_bonus_products = (contract_block) ->
  target_amount = get_target_amount(contract_block)
  monthly_payment = get_autonumeric(contract_block, '.monthly_payment') # contract_block.find('.monthly_payment').val()
  product_select = contract_block.find('.product_select')
  object_name = contract_block.find('.bonus_product_id').attr('data-object_name')
  program = get_program(contract_block)
  currency = contract_block.find('.currency').val()
  bonus_product_id = contract_block.find('.product_select.change_contract').find('.select.bonus_product_id').val()
  if object_name != '' && object_name != undefined
    product_select.html('...')
    $.get('/products/bonus_products',
          {target_amount : target_amount, program : program, currency : currency, bonus_product_id : bonus_product_id, object_name : object_name},
          (data) -> product_select.html(if data == null then '' else data),
          'html').complete ->
            set_minimal_monthly_payment_for_bonus(contract_block)
            recalculate_saving_fields(contract_block)

set_minimal_monthly_payment_text = (contract_block, limit) ->
  div = contract_block.find('.monthly_payment_minimum')
  program = get_program(contract_block)
  if program == 'commodity_account'
    label = contract_block.find('.monthly_payment_minimum_prefix_commodity_account_text').html()
  else
    label = contract_block.find('.monthly_payment_minimum_prefix_text').html()
  if limit && limit > 0
    text = label + fnumber(limit)
    div.show()
    div.html(text)
  else
    div.hide()

set_minimal_monthly_payment_for_bonus = (contract_block) ->
  if get_program(contract_block).match(/bonus/)
    product_id = contract_block.find('.bonus_product_id').val()
    currency = contract_block.find('.currency').val()
    if !!product_id
      $.get '/products/' + product_id + '.json',
            { currency : currency },
            (data) -> set_minimal_monthly_payment_text(contract_block, data['minimal_monthly_payment'])

get_program = (contract_block) ->
  if contract_block.find('.savings_type_radios').size() > 0
    contract_block.find('.program:checked').val()
  else if(contract_block.find('.contract_program').size() > 0)
    contract_block.find('.contract_program').val()
  else
    contract_block.find('#contract_program').val()

hide_or_show_entry_fee_discount_fields = (contract_block) ->
  return unless get_program(contract_block)
  discount = contract_block.find('.fixed_entry_fee_discount_field')
  value = contract_block.find('.fixed_promo_code_id').val()
  currency = contract_block.find('.currency').val()
  currency_sign = if currency == 'eur' then '€' else if currency == '$' then 'USD' else 'Kč'
  if get_program(contract_block).match(/commodity_account/)
    target_amount = parseFloat(get_target_amount(contract_block))
    discount.find('option').each ->
      if parseFloat($(this).data(currency)) <= target_amount || $(this).attr('value') == ''
        $(this).removeAttr('disabled')
      else
        contract_block.find('.fixed_promo_code_id').val('') if $(this).attr('value') == value
        $(this).attr('disabled', true)
      $(this).text("#{$(this).data('code')} - #{$(this).data("value-#{currency}")} #{currency_sign}") if $(this).attr('value') != ''

    discount.show() if discount.is(':hidden')
  else
    discount.hide()
    contract_block.find('.fixed_promo_code_id').val('')

recalculate_saving_fields = (contract_block) ->
  dealer_name = $('#dealer_name').val()
  client_id = $('#client_id').text()
  contract_id = $('#contract_id').html()
  program = get_program(contract_block)
  currency = contract_block.find('.currency').val()
  target_amount = get_target_amount(contract_block)
  if contract_block.find('.fee_at_once_false').is(':checked')
    fee_type = contract_block.find('.installments_type').val()
    ongoing_fee = if fee_type == 'ongoing_fee' then '1' else '0'
    at_start_fee = if fee_type == 'at_start_fee' then '1' else '0'
    entry_fee_in_installments = if fee_type == 'entry_fee_in_installments' then '1' else '0'
  else
    ongoing_fee = '0'
    entry_fee_in_installments = '0'
    at_start_fee = '0'
  no_discount = if contract_block.find('.no_discount input[type=checkbox]').is(':checked') then '1' else '0'
  gift_product_id = if contract_block.find('input.gift_product_id[type=checkbox]').is(':checked') then '1' else '0'
  fixed_promo_code_id = contract_block.find('.fixed_promo_code_id')?.val() || null
  second_saving_discount = if contract_block.find('.second_saving_discount').is(':checked') then '1' else '0'
  first_payment_for_whole_product = if contract_block.find('.first_payment_for_whole_product').is(':checked') then '1' else '0'
  monthly_payment = contract_block.find('.monthly_payment').val()
  bonus_product_id = contract_block.find('.bonus_product_id').val() if program.match(/bonus/)
  picked_entry_fee_discount = if contract_block.find('.second_saving_discount_picked_entry_fee_discount').size() > 0 && \
                                 second_saving_discount == '1' then \
                              contract_block.find('.second_saving_discount_picked_entry_fee_discount').val() else \
                              contract_block.find('.picked_entry_fee_discount').val()
  forgive_commodity_account_transfer_fee = if contract_block.find('.forgive_commodity_account_transfer_fee').is(':checked') then '1' else '0'
  payment_split = get_payment_split(contract_block)
  merge_contracts = contract_block.find('.merge-contract:checked').map(-> $(this).val()).get()
  if savings_data_xhr && savings_data_xhr.readyState != 4
    savings_data_xhr.onreadystatechange = null
    savings_data_xhr.abort()
    savings_data_xhr = null
  savings_data_xhr = $.ajax
    url: '/contracts/savings_data',
    data: {
            contract_id: contract_id,
            dealer_name: dealer_name, client_id: client_id, program: program,
            currency: currency, target_amount: target_amount, entry_fee_in_installments: entry_fee_in_installments,
            ongoing_fee: ongoing_fee
            at_start_fee: at_start_fee,
            no_discount: no_discount,
            second_saving_discount: second_saving_discount, gift_product_id: gift_product_id,
            first_payment_for_whole_product: first_payment_for_whole_product,
            bonus_product_id: bonus_product_id, monthly_payment: monthly_payment,
            picked_entry_fee_discount: picked_entry_fee_discount,
            payment_split: payment_split,
            merge_contracts: merge_contracts, fixed_promo_code_id: fixed_promo_code_id,
            forgive_commodity_account_transfer_fee: forgive_commodity_account_transfer_fee
          },
    success: (data) ->
          entry_fee = parseFloat(data['entry_fee'])
          entry_fee_before_discount = parseFloat(data['entry_fee_before_discount'])
          first_payment = parseFloat(data['first_payment'])
          gift_product_id = parseInt(data['gift_product_id'] || 0)
          monthly_payment = parseFloat(contract_block.find('.monthly_payment').val() || 0)
          target_amount = parseFloat(get_target_amount(contract_block) || 0)
          minimal_monthly_payment = data['minimal_monthly_payment']
          product_price = data['product_price']
          entry_fee_order_description = data['entry_fee_order_description']
          monthly_order_entry_fee_description = data['monthly_order_entry_fee_description']
          saving_length = data['saving_length']
          show_savings_promotion_picked_entry_fee_discount = data['show_savings_promotion_picked_entry_fee_discount']
          savings_promotion_discount_options = data['savings_promotion_discount_options']
          second_saving_discount_allowed = data['second_saving_discount_allowed']
          second_savings_discount_options = data['second_savings_discount_options']
          allow_offer_metal_promo_discount = data['allow_offer_metal_promo_discount']
          if second_saving_discount == '1'
            contract_block.find('.picked_entry_fee_discount').attr('disabled', 'disabled')
            contract_block.find('.second_saving_discount_picked_entry_fee_discount').removeAttr('disabled')
          else
            contract_block.find('.picked_entry_fee_discount').removeAttr('disabled')
            contract_block.find('.second_saving_discount_picked_entry_fee_discount').attr('disabled', 'disabled')
          if no_discount == '1' || (entry_fee_before_discount != entry_fee && second_saving_discount == '0' && (!show_savings_promotion_picked_entry_fee_discount || picked_entry_fee_discount == '0'))
            contract_block.find('.no_discount').show()
          else
            contract_block.find('.no_discount').hide()

          contract_block.find('.savings_promotion_picked_entry_fee_discount select').html(savings_promotion_discount_options)
          if show_savings_promotion_picked_entry_fee_discount
            contract_block.find('.savings_promotion_picked_entry_fee_discount select').removeAttr('disabled')
            contract_block.find('.savings_promotion_picked_entry_fee_discount').show()
          else
            contract_block.find('.savings_promotion_picked_entry_fee_discount').hide()
          if contract_block.find('.first_payment_for_whole_product').is(':checked') && entry_fee > 0
            product_price = fnumber(product_price)
            contract_block.find('.product_price span').html("#{product_price} #{(if currency == 'czk' then ' Kč' else ' EUR')}")
            contract_block.find('.product_price').show()
          else
            contract_block.find('.product_price').hide()
          contract_block.find('.first_payment').val(first_payment)
          contract_block.find('.entry_fee_order_description').html(entry_fee_order_description)
          contract_block.find('.entry_fee_order_description').data('entry_fee', entry_fee)
          contract_block.find('.monthly_order_entry_fee_description').html(monthly_order_entry_fee_description)
          if entry_fee_in_installments == '1'
            contract_block.find('.first_payment_discount').hide()
            contract_block.find('.gift_product_id').prop('checked', false)
          if ongoing_fee == '1' || at_start_fee == '1'
            contract_block.find('.gift_product_id').prop('checked', false);
          if entry_fee_before_discount != entry_fee && (!show_savings_promotion_picked_entry_fee_discount || picked_entry_fee_discount == '0')
            contract_block.find('.first_payment_discount_value').html(fnumber(entry_fee_before_discount - entry_fee))
            contract_block.find('.first_payment_discount').show()
          else
            contract_block.find('.first_payment_discount').hide()
          if gift_product_id == 0
            contract_block.find('.gift_product_id').prop('checked', false)
            contract_block.find('.gift_delivery_method').val('')
          else
            contract_block.find('.additional').show()
            contract_block.find('.show_additional').hide()
            contract_block.find('.gift_product_id').prop('checked', true)
          if program.match(/bonus/)
            contract_block.find('.product_saving_length').html(saving_length)
          else if program.match(/commodity_account/)
            if data['credit_transfer']
              text = "<span>#{data['credit_transfer']['transferred_label']}</span>"
              if(data['credit_transfer']['blocked'] > 0)
                text += " <span>#{data['credit_transfer']['blocked_label']}</span> <span>#{data['credit_transfer']['available_label']}</span>"
              contract_block.find('.credit_transfer').html(text)
            else
              contract_block.find('.credit_transfer').html('')
          else
            set_minimal_monthly_payment_text(contract_block, minimal_monthly_payment)
          hide_or_show_entry_fee_discount_fields(contract_block)
          contract_block.find('.second_saving_discount_picked_entry_fee_discount').html(second_savings_discount_options)
          if second_saving_discount_allowed
            contract_block.find('.second_saving_discount').removeAttr('disabled')
          else
            contract_block.find('.second_saving_discount').prop('checked', false)
            contract_block.find('.second_saving_discount').attr('disabled', 'disabled')
          if monthly_payment > 0
            contract_block.find('.payment_splits_configuration').show()
          if allow_offer_metal_promo_discount
            contract_block.find('.metal_crypto_discount_field').show()
            contract_block.find('.metal_crypto_discount_field .offer_metal_crypto_discount').prop('disabled', false)
          else
            contract_block.find('.metal_crypto_discount_field').hide()
            contract_block.find('.metal_crypto_discount_field .offer_metal_crypto_discount').prop('disabled', true)

get_payment_split = (contract_block) ->
  payment_split = []
  contract_block.find('.payment_split_product_percentage_configuration').each ->
    row = $(@)
    product_id = row.find('.payment_split_product').val()
    percentage = row.find('.payment_split_percentage').val()
    payment_split.push([product_id, percentage])
  payment_split

window.activate_contract_add_voucher_field_link = (link_id) ->
  $("##{link_id}").on 'click', (event) ->
    new_input = $(this).parent().find('.voucher_value').last().clone()
    new_input.val('')
    new_input.addClass('more_than_first_contract_voucher_value')
    $(this).before('<br>')
    $(this).before(new_input)
    event.preventDefault()

window.redraw_targets_amount_table = ->
  $('.program, .currency').change ->
    contract_block = $(this).parents('.contract_fieldsets')
    program = get_program(contract_block)
    currency = contract_block.find('.currency').val()
    fee_type = contract_block.find('.installments_type').val()
    table = contract_block.find('.target_amounts_table')
    if program
      $.get '/contracts/target_amounts_table', {program: program, fee_type: fee_type, currency: currency},
            (data) -> table.html(data)

window.change_terms_link = ->
  $(document).on 'change', '.program', ->
    contract_block = $(this).parents('.contract_fieldsets')
    saving_type = contract_block.find('.programs_groups:checked').val()
    link = contract_block.find('.terms_link')
    if saving_type
      saving_type = "-#{saving_type}"
    link.prop('href', "/files/vop#{saving_type}.pdf")

window.toggle_instalments_type_select = ->
  $('.fee_at_once').change ->
    contract_block = $(this).parents('.contract_fieldsets')
    contract_block.find('.installments_type').toggle()
    contract_block.find('.had_fee_at_once_when_reached_target_amount').toggle()

window.contract_preview_link = (client) ->
  $(document).on 'click', '.contract_preview_link', (event) ->
    event.preventDefault()
    a = $(this)
    form = document.createElement('form');
    form.setAttribute('method', 'post')
    form.setAttribute('action', a.attr('href'))
    form.setAttribute('target', '_blank')
    document.body.appendChild(form)

    inputs = [$('input[name=authenticity_token]')]
    a.parents('form').find('input, select, textarea').each ->
      if $(@).attr('name') && $(@).attr('name').match(/client\[/)
        field = $(@).attr('name').match(/^client\[([^]]*)\]$/)
        if field
          client[field[1]] = $(@).val()
        else
          inputs.push($(@))
    a.parents('.contract_fieldsets').find('input, select, textarea').each ->
      inputs.push($(@))
    for k,v of client
      if v == false
        continue
      input = document.createElement('input')
      input.type = 'hidden'
      input.name = "client[#{k}]"
      input.value = v
      form.appendChild(input)
    $(inputs).each ->
      if $(@).attr('type') != 'radio' && $(@).attr('type') != 'checkbox' || $(@).is(':checked')
        input = document.createElement('input')
        input.type = 'hidden'
        input.name = $(@).attr('name')
        input.value = $(@).val()
        form.appendChild(input)
    id = a.parents('form').attr('action').replace(/\/clients\/?(.*)/, '$1')
    if id != ''
      input = document.createElement('input')
      input.type = 'hidden'
      input.name = 'client[id]'
      input.value = id
      form.appendChild(input)

    form.submit()
    document.body.removeChild(form)

window.change_savings_delivery_method = ->
  $(document).on 'change', '.storing_contract_signed', ->
    delivery_method = $(this).parents('.contract_fieldsets').find('.delivery_method')
    if $(this).is(':checked')
      delivery_method.val('2')
    else
      delivery_method.val('3')

window.propagate_delivery_method = ->
  $(document).on 'change', '.delivery_method', ->
    delivery_method = $(this).val()
    $(this).parents('form').find('.delivery_method').each ->
      if $(@).val() == ''
        $(@).val(delivery_method)


window.toggle_saving_products = ->
  $(document).on 'change', '#contract_program', ->
    contract_block = $(this).parents('.contract_fieldsets')
    if $(this).val() == 'commodity_account'
      contract_block.find('.bonus_product_fields').hide()
      contract_block.find('.payment_splits_configuration').show()
    else
      contract_block.find('.payment_splits_configuration').hide()
      contract_block.find('.bonus_product_fields').show()

sum_for_merge = (attr, factor) ->
  value = parseFloat($('#contract').attr(attr))
  if $('.merge-contract:checked').length > 0
    increase = $('.merge-contract:checked').get().map((field)->
      parseFloat($(field).attr(attr))
    ).reduce ((a,b) -> a + b), 0
  else
    increase = 0

  value + increase * factor

window.update_target_amount_and_monthly_payment = ->
  $(document).on 'change', '.merge-contract', ->
    ratio = parseFloat($('#contract').attr('data-merge-ratio'))
    any_checked = $('.merging-contract:checked').length > 0
    if any_checked
      $('#merge-contract-notice').show()
      $('#forgive_fee_li').show()
    else
      $('#merge-contract-notice').hide()
      $('#forgive_fee_li').hide()

    $('#contract_target_amount').val(sum_for_merge('data-target-amount', ratio).toFixed(1))
    $('#contract_monthly_payment').val(sum_for_merge('data-payment', 1).toFixed(1))
    update_payment_split_element($(this))
    recalculate_saving_fields($(this).parents('.contract_fieldsets'))

window.paper_received_date = ->
  $(document).on 'change', '.paper_received', ->
    contract_block = $(this).parents('.contract_fieldsets')
    date_field = contract_block.find('.paper_received_date')
    place_field = contract_block.find('.paper_received_place')
    if $(this).is(':checked')
      date_field.val(date_field.data('today'))
      place_field.val(place_field.data('ffc'))
    else
      date_field.val('')
      place_field.val('')
  $(document).on 'change', '.paper_received_in_hq', ->
    contract_block = $(this).parents('.contract_fieldsets')
    date_field = contract_block.find('.paper_received_in_hq_date')
    if $(this).is(':checked')
      date_field.val(date_field.data('today'))
    else
      date_field.val('')

update_payment_split_element = (element) ->
  contract_block = element.parents('.contract_fieldsets')
  contract_block.find('.refresh_payment_split').addClass('fa-spin')
  program = get_program(contract_block)
  currency = contract_block.find('.currency').val()
  target_amount = get_target_amount(contract_block)
  target = contract_block.find('.payment_splits_configuration')
  client_id = target.data('client-id')
  contract_id = target.data('contract-id')
  payment_split = get_payment_split(contract_block)
  merge_contracts = $('.merge-contract:checked').map(-> $(this).val()).get()
  if program == 'commodity_account'
    $.get '/contracts/payment_split', {client_id: client_id, contract_id: contract_id, program: program, target_amount: target_amount, currency: currency, merge_contracts: merge_contracts, payment_split: payment_split}, (data) ->
      target.html(data)
      update_whole_products_saved_info(contract_block)
      update_bonus_grams_purchase_notice(contract_block)
      contract_block.find('.refresh_payment_split').removeClass('fa-spin')
  else
    contract_block.find('.refresh_payment_split').removeClass('fa-spin')

is_payment_split_correct = (contract_block) ->
  program = get_program(contract_block)
  if program != 'commodity_account'
    return true
  if contract_block.find('.payment_split_percentage').size() == 0
    return true
  target = contract_block.find('.payment_splits_configuration')
  contract_id = target.data('contract-id')
  payment_split_sum = contract_block.find('.payment_split_percentage').map(-> parseFloat($(this).val().replace(',','.')) || 0).get().reduce (t, s) -> t + s
  payment_split_sum == 100

reenable_submit_buttons = (form)->
  form.find('input[type=submit]:disabled').each ->
    btn = $(this)
    btn.removeAttr('disabled')
    btn.val(btn.data('reenableTo'))

disable_submit_buttons = (form)->
  form.find('input[type=submit]').each ->
    btn = $(this)
    btn.attr('disabled', true)

get_autonumeric = (contract_block, locator)->
  element = contract_block.find(locator)[0]
  autonumeric = AutoNumeric.getAutoNumericElement(element)
  if autonumeric
    autonumeric.get()
  else
    $(element).val()


get_target_amount = (contract_block)->
  get_autonumeric(contract_block, '.target_amount')

window.update_payment_split = ->
  $(document).on 'change', '.target_amount, .program, #contract_program', ->
    update_payment_split_element($(this))
  $(document).on 'click', '.refresh_payment_split', ->
    update_payment_split_element($(this))

payment_split_correct = (form)->
  form.find('.contract_fieldsets').map(-> is_payment_split_correct($(this))).get().reduce( ((t,s) -> t && s), true)

window.guard_payment_split_live = (message, error_element_id)->
  $(document).on 'keyup', '.payment_split_percentage', (e) ->
    form = $(@).parents('form')
    if payment_split_correct(form)
      $(error_element_id).text('')
      $(error_element_id).hide()
      reenable_submit_buttons(form)
    else
      $(error_element_id).text(message)
      $(error_element_id).show()
      disable_submit_buttons(form)

window.guard_payment_split = (message)->
  $(document).on 'submit', '.edit_client, .new_client, .edit_contract, .payment_split_change', (e) ->
    form = $(this)
    if !payment_split_correct(form)
      reenable_submit_buttons(form)
      e.preventDefault()
      e.stopPropagation()
      alert(message)
      false

window.set_payment_percentages = ->
  $(document).on 'click', '.set_payment_percentages', (e) ->
    e.preventDefault()
    percentages = $(this).data('percentages').split(':')
    contract_block = $(this).parents('.contract_fieldsets')
    contract_block.find('.payment_split_percentage').each (index, element) ->
      elem = $(element).val(percentages[index] || 0)
    if contract_block.find('.payment_split_percentage').size() > 0
      $(contract_block.find('.payment_split_percentage')[0]).change()


window.keep_updating_whole_products_saved_info = ->
  $(document).on 'change', '.currency, .monthly_payment, .payment_split_product, .payment_split_percentage', (e) ->
    contract_block = $(this).parents('.contract_fieldsets')
    update_whole_products_saved_info(contract_block)
    update_bonus_grams_purchase_notice(contract_block)

window.update_whole_products_saved_info = (contract_block) ->
    program = get_program(contract_block)
    return if program != 'commodity_account'
    payment_split = get_payment_split(contract_block)
    monthly_payment = get_autonumeric(contract_block, '.monthly_payment') #contract_block.find('.monthly_payment').val()
    return unless monthly_payment?
    currency = contract_block.find('.currency').val()
    $.get '/contracts/whole_products_saved_info', {monthly_payment: monthly_payment, currency: currency, payment_split: payment_split}, (data) ->
      for product_id, product_data of data
        price = product_data[0]
        months = product_data[1]
        product_option = contract_block.find(".payment_split_product option:selected[value=#{product_id}]")
        months_span = product_option.parents('.payment_split_product_percentage_configuration').find('.months')
        price_span = product_option.parents('.payment_split_product_percentage_configuration').find('.price')
        if months_span
          months_span.parents('.save_whole_product_info').show()
          if parseFloat(months) > 0
            months_span.html(months)
            price_span.html(price)
          else
            months_span.parents('.save_whole_product_info').hide()

window.update_bonus_grams_purchase_notice = (contract_block) ->
  target_amount = get_autonumeric(contract_block, '.target_amount')
  entry_fee = contract_block.find('.entry_fee_order_description').data('entry_fee')
  payment_split = get_payment_split(contract_block)
  currency = contract_block.find('.currency').val()
  $.get '/contracts/bonus_grams_purchase_notice', { target_amount: target_amount, entry_fee: entry_fee, currency: currency, payment_split: payment_split }, (data) ->
    contract_block.find('.bonus_grams_purchase_notice').html(data)

window.toggle_recalculate_partial_products = ->
  $(document).on 'change', '.payment_split_product, .payment_split_percentage, .recalculate_partial_products', (e) ->
    product_changed = false
    percentage_changed = false
    contract_block = $(this).parents('.contract_fieldsets')
    contract_block.find('.payment_split_product_percentage_configuration').each ->
      product_select = $(@).find('.payment_split_product')
      current_product_id = parseInt(product_select.val())
      original_product_id = parseInt(product_select.data('original-value'))
      if current_product_id != original_product_id && product_select.data('has-grams')
        product_changed = true

      percentage_input = $(@).find('.payment_split_percentage')
      if parseFloat(percentage_input.val()) != parseFloat(percentage_input.data('original-value'))
        percentage_changed = true

      $(".convert_#{original_product_id}").hide()
      if $('#recalculate_partial_products_1').is(':checked')
        $("#convert_#{original_product_id}_to_#{current_product_id}").show()
      else
        $("#convert_#{original_product_id}_to_#{original_product_id}").show()

    option_div = contract_block.find('.recalculate_partial_products_div')
    partial_grams_conversion_div = contract_block.find('.partial_grams_conversion')
    if product_changed
      option_div.show()
      partial_grams_conversion_div.show()
      if $(this).attr('class').match(/payment_split_product/)
        $('#recalculate_partial_products_1').prop('checked', true).trigger('change')
    else
      option_div.hide()
      partial_grams_conversion_div.hide()
      $('#recalculate_partial_products_0').prop('checked', true)
    $('#warning_changing_product').data('show', product_changed)
    $('#warning_changing_percentage').data('show', percentage_changed)

window.recalculate_amounts_for_new_currency = ->
  $(document).on 'change', '.currency', (e) ->
    to_currency = $(this).val()
    $('#contract_target_amount').val($('#contract').data("target-amount-#{to_currency}"))
    highlight($('#contract_target_amount'))
    $('#contract_monthly_payment').val($('#contract').data("monthly-payment-#{to_currency}"))
    highlight($('#contract_monthly_payment'))

window.show_payment_split_change_warning = ->
  $(document).on 'submit', '.payment_split_change', (e) ->
    if e.isDefaultPrevented()
      return
    warnings = []
    if $('#warning_changing_product').data('show') && $('#recalculate_partial_products_1').is(':checked')
      warnings.push($('#warning_changing_product').html())
    if $('#warning_changing_percentage').data('show')
      warnings.push($('#warning_changing_percentage').html())
    if warnings.length > 0
      warnings.push($('#do_you_want_change').html())
      warning_text = warnings.join("\n\n")
    else
      warning_text = ''
    if warning_text != '' && !confirm(warning_text)
      e.preventDefault()

window.monitor_overcharge_transfer = (element) ->
  url = element.data('reload')
  if url
    reload = ->
      element.parents('.overcharge_wrapper').load(url)
    setTimeout reload, 10000

window.dismiss_overcharge_transfer_notification = (element) ->
  url = $(element).data('dismiss')
  if url
    $.post url, { transfer_id: $(element).data('transfer') }, (data, response) ->
      transfer_id = $(element).data('transfer')
      contract_id = $(element).data('contract')
      $('#recent_finished_transfer_'+transfer_id).remove()
      if $('#recent_transfer_'+contract_id+' .transfer').length == 0
        $('#recent_transfer_'+contract_id).hide()

window.metals_for_purchase_of_bonus_grams = ->
  $(document).on 'change', '.bonus_grams_purchase', (e) ->
    checkboxes = $(this).parent().find('.metals_for_purchase_of_bonus_grams')
    if $(this).val() == 'automatic_selected'
      checkboxes.show()
    else
      checkboxes.hide()
    if $(this).val() == 'automatic_all' || $(this).val() == 'automatic_selected'
      $(this).parents('.bonus_grams_purchase_li').find('.bonus_grams_purchase_notice').show()
    else
      $(this).parents('.bonus_grams_purchase_li').find('.bonus_grams_purchase_notice').hide()

window.calculate_crypto_currency_split = (elem, my_class) ->
    split = $(elem).val()
    split = Math.round(parseInt(split))
    if split < 0
      split = 0
    if split > 100
      split = 100
    split = split - (split % 5)

    $(elem).val(split)
    $('.payment_split_percentage:not(.' + my_class + ')').val(100-split)

window.show_aml_notice = ->
  $(document).on 'change', '.payment_split_product', ->
    aml_product_ids = $('#aml_notice').data('ids') || []
    if parseInt($(this).val()) in aml_product_ids
      $('#aml_notice').show()
    else
      $('#aml_notice').hide()

jQuery ->
  $('.autonumeric').each ->
    new AutoNumeric this,
    {
      decimalPlaces: $(this).data('decimal-places') || 0
      decimalCharacter: ','
      digitGroupSeparator: ' '
      unformatOnSubmit: true
      watchExternalChanges: true
    }

  show_hide_dealer_contract_fields()
  show_hide_client_contract_fields()

  $('.contract_fieldsets').each ->
    hide_or_show_entry_fee_discount_fields($(this))
  $('.dealer_is_company').click ->
    show_hide_dealer_contract_fields()
  $('.client_is_company, .pawn_is_company').click ->
    show_hide_client_contract_fields()

  $(document).on 'change', '.monthly_payment, .target_amount, .entry_fee_in_installments, .fee_at_once, .installments_type, .program, .currency, .bonus_product_id, .no_discount, .second_saving_discount, .gift_product_id, .first_payment_for_whole_product, .picked_entry_fee_discount, .second_saving_discount_picked_entry_fee_discount, .payment_split_percentage, .payment_split_product, .forgive_commodity_account_transfer_fee, .fixed_promo_code_id' , ->
    contract_block = $(this).parents('.contract_fieldsets')
    program = get_program(contract_block)
    return unless program?

    if $(this).attr('id').match(/program/) || $(this).attr('id').match(/monthly_payment/) || $(this).attr('id').match(/target_amount/)
      if program.match(/bonus/)
        contract_block.find('.bonus_product_fields').show()
        set_bonus_products(contract_block)
      else
        contract_block.find('.bonus_product_fields').hide()
        set_bonus_products(contract_block)
        recalculate_saving_fields(contract_block)
    else if contract_block.find('.target_amount').size() > 0
      recalculate_saving_fields(contract_block)

  $(document).on 'change', '.bonus_product_id', ->
    contract_block = $(this).parents('.contract_fieldsets')
    if get_program(contract_block).match(/bonus/)
       set_minimal_monthly_payment_for_bonus(contract_block)
       recalculate_saving_fields(contract_block) if contract_block.find('.first_payment_for_whole_product').is(':checked')
