change_timeline = ->
  date = $('#buyout_appointment_date').val()
  timeline = $('#buyouts_timeline')
  ffc_id = $('#buyout_appointment_ffc_id').val()
  if date != '' && ffc_id != ''
    size = 1
    timeline.html('...')
    $.get '/buyout_appointments/timeline', {date: date, ffc_id: ffc_id, size: size},
      (data) ->
        timeline.html(data)
        timeline.show()
  else
    timeline.html('')

update_business_hours = ->
  ffc_id = $('#buyout_appointment_ffc_id').val()
  business_hours = $('#buyout_appointment_business_hours')
  business_hours.html('')
  if ffc_id != ''
    $.get '/buyout_appointments/business_hours', {ffc_id: ffc_id},
      (data) ->
        business_hours.html(data)
  else
    business_hours.html('')

update_buyout_at = ->
  ffc_id = $('#buyout_appointment_ffc_id').val()
  buyout_appointment_at = $('#buyout_appointment_at')
  buyout_appointment_at.find('input').prop('disabled', 'disabled')
  timeline = $('#buyout_appointment_timeline')
  timeline.html('...')
  $('input[type="submit"]').prop('disabled', true)
  if ffc_id != ''
    $.get '/buyout_appointments/buyout_appointment_at', {ffc_id: ffc_id},
      (data) ->
        buyout_appointment_at.html(data)
        $('.date').datepicker({beforeShowDay: decideDateShown})
        buyout_appointment_at.show()
        $('input[type="submit"]').prop('disabled', false)
        change_timeline()

window.handle_buyout_appointment_ffc_id_change = ->
  $(document).on 'change', '#buyout_appointment_ffc_id', ->
    update_business_hours()
    update_buyout_at()
    change_timeline()

window.handle_buyout_timeline_change = ->
  $(document).on 'change', '#buyout_appointment_date', ->
    change_timeline()

window.handle_buyout_timeline_click = ->
  $(document).on 'click', '#timeline div.available', (event) ->
    event.preventDefault()
    $('#timeline div.available.selected').removeClass('selected')
    $(this).addClass('selected')
    [hours, mins] = $(this).data('starts-at').split(':')
    $('#buyout_appointment_at_4i').val(hours)
    $('#buyout_appointment_at_5i').val(mins)

window.handle_buyout_account_number_format = ->
  $(document).on 'change', '#buyout_appointment_account_in_iban_format', ->
    if $(this).is(':checked')
      $('.iban_format').show()
      $('.short_account_format').hide()
    else
      $('.iban_format').hide()
      $('.short_account_format').show()
