window.initSimpleTree = ->
  if $('.simpleTree').simpleTree
    $('.simpleTree').simpleTree({
    drag: false,
    autoclose: true,
    animate: true,
    afterAjax: (node) ->
      if history.pushState
        url = $('#simple-tree-container').data('dealers_url')
        name_with_id = node.parent().data('name_with_id')
        sep = if url.indexOf('?') == -1 then '?' else '&'
        url = "#{url}#{sep}dealer_name=#{name_with_id}"
        history.pushState({}, window.title, url.toString())
    })

window.expand_all_nodes = ->
  $('.simpleTree li').each ->
    if !$(this).children('ul').hasClass('ajax')
      if $(this).hasClass('folder-close')
        $(this).addClass('folder-open')
        $(this).removeClass('folder-close')
        $(this).children('ul').show()
      else if $(this).hasClass('folder-close-last')
        $(this).addClass('folder-open-last')
        $(this).removeClass('folder-close-last')
        $(this).children('ul').show()

window.expand_all_tree_nodes = ->
  $('#spinner-icon').show();
  $.get "/dealers/simple_tree/", (data) ->
    $('#simple-tree-container').html(data)
    initSimpleTree()
    expand_all_nodes()
    $('#spinner-icon').hide()

window.collapse_all_nodes = ->
  $('.simpleTree li').each ->
    if $(this).hasClass('folder-open')
      $(this).removeClass('folder-open')
      $(this).addClass('folder-close')
      $(this).children('ul').hide()
    else if $(this).hasClass('folder-open-last')
      $(this).removeClass('folder-open-last')
      $(this).addClass('folder-close-last')
      $(this).children('ul').hide()

window.shipping_address_toggle = ->
  $('#dealer_shipping_address_different').click ->
    if $('#dealer_shipping_address_different').is(':checked')
      $('.shipping_address').show()
    else
      $('.shipping_address').hide()

window.fill_dealer_form_after_client_suggest = (event, ui) ->
  if ui.item && id = ui.item.value.match(/\(.*- (\d+)\)/)
    $('input').prop('disabled', true)
    client_url = '/clients/' + id[1] + '.json'
    $.get client_url, {for_dealer_creation: true}, (data, response) ->
      if data['is_company']
        $('#dealer_legal_kind_2').prop('checked', false);
        $('#dealer_legal_kind_3').prop('checked', true);
        $('.contract_fields .person').hide();
        $('.contract_fields .company').show();
      else if !data['is_company']
        $('#dealer_legal_kind_3').prop('checked', false);
        $('#dealer_legal_kind_2').prop('checked', true);
        $('.contract_fields .company').hide();
        $('.contract_fields .sole_trader').show();
        $('.contract_fields .person').show();

      params = ['degree_before', 'degree_after', 'gender', 'first_name', 'last_name', 'company', 'street',
                'city', 'zip_code', 'identification_number', 'birth_date',
                'email', 'phone', 'country', 'full_phone_number']
      $.each params, (index, field) ->
        $('#dealer_' + field).val(data[field])

    $('input').prop('disabled', false)

window.fill_dealer_form_after_no_contract_client_suggest = (event, ui) ->
  if ui.item && id = ui.item.value.match(/\((\d+)\)/)
    $('.no_contract_client_id').val(id[1])
    $('input').prop('disabled', true)
    client_url = '/no_contract_clients/' + id[1] + '.json'
    $.get client_url, {for_dealer_creation: true}, (data, response) ->
      if data['is_company']
        $('#dealer_legal_kind_2').prop('checked', false);
        $('#dealer_legal_kind_3').prop('checked', true);
        $('.contract_fields .person').hide();
        $('.contract_fields .company').show();
      else if !data['is_company']
        $('#dealer_legal_kind_3').prop('checked', false);
        $('#dealer_legal_kind_2').prop('checked', true);
        $('.contract_fields .company').hide();
        $('.contract_fields .sole_trader').show();
        $('.contract_fields .person').show();

      params = ['degree_before', 'degree_after', 'gender', 'first_name', 'last_name', 'company', 'street',
                'city', 'zip_code', 'identification_number', 'birth_date',
                'email', 'phone', 'country', 'full_phone_number']
      $.each params, (index, field) ->
        $('#dealer_' + field).val(data[field])

    $('input').prop('disabled', false)

window.file_upload_fields_toggle = ->
  $('.show_file_upload_field').click (event) ->
    event.preventDefault()
    $(this).parent().find('.uploaded_link').hide()
    $(this).parent().find('a').hide()
    field = $(this).attr('id').replace(/show_file_upload_field_/, '')
    $("#dealer_#{field}").show()

window.morning_news_toggle = ->
  $('#dealer_type').change ->
    if $(this).val() == 'recommender'
      $('#dealer_wants_morning_news').prop('checked', false)
    else
      $('#dealer_wants_morning_news').prop('checked', true)


window.notifications_toggle = ->
  $('#user_notify_one_offs, #user_notify_savings').on 'change', ->
    if $('#user_notify_one_offs').prop('checked') || $('#user_notify_savings').prop('checked')
      radios = $('input:radio[name*=notify_dealers_group]').toArray()
      any_checked = radios.some (radio) ->
        $(radio).prop('checked') == true
      $('#user_notify_dealers_group_direct_subdealers').prop('checked', true) unless any_checked

window.allow_any_dealer = ->
  init_toggle('show_allow_dealer_section_link', true)
  $('#allow_any_dealer').change ->
    if $(this).is(':checked')
      $('#dealer_recommended_by_dealer_id').autocomplete({
        source: '/dealer_suggests/new?force_all=true'
      })
    else
      $('#dealer_recommended_by_dealer_id').autocomplete({
        source: '/dealer_suggests/new'
      })
window.refresh_regions = ->
  $(document).on 'change', '#dealer_country', ->
    country = $(this).val()
    if country
      $('#dealer_primary_region_and_county').prop('disabled', true)
      $('#dealer_secondary_region_and_county').prop('disabled', true)
      $.ajax
        url: "/dealers/regions_form_row"
        type: "get"
        data: { country: country }
        success: (data) ->
          $('#regions_form_row').html(data)
        error: ->
          alert 'Chyba'

window.update_dealer_vocative_note = ->
  placeholder = $('#dealer_vocative').attr('placeholder')
  vocative = $('#dealer_vocative').val()
  if (!!placeholder && !vocative)
    $('#dealer_vocative').parents('li').find('span.vocative').show()
  else
    $('#dealer_vocative').parents('li').find('span.vocative').hide()

window.suggest_dealer_vocative = ->
  if (!$('#dealer_vocative').val() && !!$('#dealer_last_name').val() && !!$('#dealer_gender').val())
    url = '/vocatives/suggest'
    params =
      name:  $('#dealer_last_name').val()
      gender: $('#dealer_gender').val()

    $.get url, params, (data, response) ->
      $('#dealer_vocative').attr('placeholder', data['vocative'])
      update_dealer_vocative_note()

window.toggle_email_for_ics = ->
  $(document).on 'keyup', '#dealer_ical', ->
    element = $('#email_for_ics_div')
    if $('#dealer_ical').val() == ''
      element.hide()
    else
      element.show()

window.show_hide_dealer_iban_row = ->
  $(document).on 'change', '#dealer_commissions_currency', ->
    if $(this).val() == 'czk'
      $('.iban_form_row').hide()
    else
      $('.iban_form_row').show()

window.show_hide_charity_contribution_amount= ->
  $(document).on 'change', '#dealer_contributes_to_charity', ->
    if $(this).is(':checked')
      $('#charity_contribution_amount').show()
    else
      $('#charity_contribution_amount').hide()

window.show_category_changes = ->
  $(document).on 'click', '#show_dealer_category_changes', (event) ->
    event.preventDefault()
    $(this).hide()
    $('#show_dealer_category_changes_indicator').show()
    dealer_id = $(this).data('dealer-id')
    $.ajax
      url: "/dealers/#{dealer_id}/dealer_category_changes"
      type: "get"
      data: {}
      success: (data) ->
        $('#category_changes').html(data)
      error: ->
        alert 'Chyba'

window.show_dealer_points_limit_history = ->
  $(document).on 'click', '#show_dealer_points_limit_history', (event) ->
    event.preventDefault()
    $(this).hide()
    $('#dealer_points_limit_history').show()
    dealer_id = $(this).data('dealer-id')
    $.ajax
      url: "/dealers/#{dealer_id}/dealer_points_limit_histories"
      type: "get"
      data: {}
      success: (data) ->
        $('#dealer_points_limit_history').html(data)
      error: ->
        alert 'Chyba'

window.loadDealerActions = ->
  dealer_ids = []
  for el in $('.dealer-actions-loading')
    do ->
      dealer_ids.push($(el).data('dealer-id'))
  $.ajax
    url: '/dealers/dealer_actions',
    type: 'get',
    data: { dealer_ids: dealer_ids }
    success: (data) ->
      for dealer_id, actions of data['dealer_actions']
        dealer_actions_element = $(".dealer-actions-loading[data-dealer-id='#{dealer_id}']")
        dealer_actions_element.html(actions)
        dealer_actions_element.removeClass('dealer-actions-loading')
    error: ->
      $('.icon_loading').attr('title', 'Chyba, ikonku se nepodařilo načíst.')

window.show_hide_crypto_dealer_invoicing_details = ->
    $(document).on 'change', 'input[name="same_as_for_metal"]', ->
      $('#metal_ivoicing_details').toggle()
      $('#crypto_ivoicing_details').toggle()

window.show_hide_is_vat_payer_fields = ->
  if $('#dealer_vat_payer_type').val() == 'vat_payer' || $('#dealer_is_vat_payer_since').val() != ''
    $('.is_vat_payer_since').show()
    $('.is_vat_payer_until').show()
  else
    $('.is_vat_payer_since').hide()
    $('.is_vat_payer_until').hide()

window.fill_is_vat_payer_until = ->
  if $('#dealer_vat_payer_type').val() != 'vat_payer' && $('#dealer_is_vat_payer_since').val() != ''
    $('#dealer_is_vat_payer_until').val($('#dealer_is_vat_payer_until').data('current-date'))
  else if $('#dealer_vat_payer_type').val() == 'vat_payer'
    $('#dealer_is_vat_payer_until').val('')

window.show_hide_vat_identification_number = ->
  $(document).on 'change', '#dealer_country', ->
    toggle_vat_identification_number()

window.toggle_vat_identification_number = ->
  if $('.country').val() == 'sk'
    $('.vat_identification_number').show()
  else
    $('.vat_identification_number').hide()

window.load_company_statistics = ->
  $(document).on 'mouseover', '#company_statistics_corner', ->
    item = $('#company_statistics_corner_content')
    if (item.hasClass('blank'))
      item.removeClass('blank')
      item.load '/portal/firemni-vysledky'

window.show_commission_row = ->
  $(document).on 'click', '#show_commission_row', (e) ->
    e.preventDefault()
    $(this).hide()
    $('.commission_row').show()
    for a in $('.monthly_points_tab_link')
      do ->
        href = $(a).attr('href')
        href += '&show_commissions=true'
        $(a).attr('href', href)

window.show_countries_points = ->
  $(document).on 'click', '.show_countries_points', (e) ->
    e.preventDefault()
    $(this).parents('table').find('.countries_points').toggle()

window.update_labels = (kind) ->
  currency = $("#test_account_#{kind}_account_currency").val()
  $("label[for=test_account_first_#{kind}_account_payment]").html($("#first_#{kind}_account_payment_#{currency}").html())

window.update_test_account_fields = ->
  update_labels('commodity')
  update_labels('crypto')
  update_labels('investment')

  $(document).on 'change', '#test_account_commodity_account', ->
    if $(this).is(':checked')
      $('.commodity_account').show()
    else
      $('.commodity_account').hide()
  $(document).on 'change', '#test_account_crypto_account', ->
    if $(this).is(':checked')
      $('.crypto_account').show()
    else
      $('.crypto_account').hide()

  $(document).on 'change', '#test_account_investment_account', ->
    if $(this).is(':checked')
      $('.investment_account').show()
    else
      $('.investment_account').hide()

  $(document).on 'change', '#test_account_commodity_account_currency', ->
    currency = $(this).val()
    target_amount = $('#test_account_commodity_account').data(currency)
    $('#commodity_target_amount').html(target_amount)
    update_labels('commodity')

  $(document).on 'change', '#test_account_crypto_account_currency', ->
    currency = $(this).val()
    target_amount = $('#test_account_crypto_account').data(currency)
    $('#crypto_target_amount').html(target_amount)
    update_labels('crypto')

  $(document).on 'change', '#test_account_investment_account_currency', ->
    currency = $(this).val()
    target_amount = $('#test_account_investment_account').data(currency)
    $('#investment_target_amount').html(target_amount)
    update_labels('investment')

$ ->
  update_dealer_vocative_note()

  $(document).on 'keypress', '#dealer_vocative', ->
    update_dealer_vocative_note()

  $(document).on 'blur', '#dealer_last_name', ->
    suggest_dealer_vocative()

  $(document).on 'change', '#dealer_gender', ->
    suggest_dealer_vocative()
