window.toggle_cancel_order_options = ->
  $('#order_status').change ->
    if $(this).val() == '4'
      $('.cancel_order_options').show()
    else
      $('.cancel_order_options').hide()

window.show_change_to_deliver_by_post_info = ->
  $('#order_delivery_method').change ->
    if ($(this).val() == '1' || $(this).val() == '5') && $('#order_to_post_delivery_method_info').length > 0
      $(this).parent().attr('style', 'height: 80px')
      $('#order_to_post_delivery_method_info').show()
    else
      $('#order_to_post_delivery_method_info').hide()
      $(this).parent().attr('style', '')

    if $(this).val() != '1' && $('#order_from_post_delivery_method_info').length > 0
      $('#order_from_post_delivery_method_info').show()
    else
      $('#order_from_post_delivery_method_info').hide()

window.get_crypto_estimates = (order_id) ->
  $.get "/crypto_estimates/#{order_id}",
    (data) ->
      if data['coins_btc']
        $('#coins_estimate_btc').html(data['coins_btc'])
        $('#exchange_rate_estimate_btc').html(data['exchange_rate_btc'])
      if data['coins_eth']
        $('#coins_estimate_eth').html(data['coins_eth'])
        $('#exchange_rate_estimate_btc').html(data['exchange_rate_eth'])
