changing_wholesale_client = ->
  $('#payment_wholesale_customer_id').on 'change', ->
    window.location.href = window.location.pathname + '?wholesale_customer_id=' + $(this).val()

update_amount_to_pay = ->
  sum = 0
  $('.wholesale_order_amount').each ->
    if $(this).siblings('input[type=checkbox]').is(':checked')
      sum += parseFloat($(this).val())
  $('.settling_payment_amount').each ->
    if $(this).siblings('input[type=checkbox]').is(':checked')
      sum += parseFloat($(this).val())
  amount = parseFloat($('#payment_amount').val())
  formatter = new Intl.NumberFormat('cs-CZ', style: 'currency', currency: $('#payment_currency').val())
  left = amount - sum
  if Math.abs(left) <= 0.01
    left = 0
  $('#total_used_from_payment').text(formatter.format(sum))
  $('#total_left_on_payment').text(formatter.format(left))

  if $('.wholesale_order_amount').size() > 0
    if left != 0
      $('input[type=submit]').prop disabled: true
    else
      $('input[type=submit]').prop disabled: false

window.toggle_wholesale_orders_on_payment_kind_change = ->
  changing_wholesale_client();
  $('#payment_kind').on 'change', ->
    val = $(this).val()
    if val == 'wholesale'
      $('#wholesale_orders').show()
    else
      $('#wholesale_orders').hide()

window.initialize_wholesale_orders_payment = ->
  update_amount_to_pay()
  $('#wholesale_orders').find('input[type=checkbox]').on('change', update_amount_to_pay)
  $('#wholesale_orders').find('input[type=text]').on('keyup', update_amount_to_pay)

window.initialize_settling_payments = ->
  update_amount_to_pay()
  $('.settling_payment_checkbox').on('change', update_amount_to_pay)
