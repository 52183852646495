$(document).on 'change', '.user_check_box', (event) ->
  element = $(this)
  record_id = element.data('record-id')
  check_type = element.data('check-type')
  record_type = if element.data('record-type') then element.data('record-type') else null
  amount = element.val()
  checked = element.is(':checked')
  checked_count_field_id = element.data('checked-count-field-id')
  if (element.is(':checkbox') && checked) || (!element.is(':checkbox') && amount > 0)
    path = ''
  else
    path = '/delete'
  $('.user_check_box, input[type="submit"]').prop('disabled', true)
  url = "/user_checks#{path}.json?record_id=#{record_id}&check_type=#{check_type}&amount=#{amount}"
  if record_type
    url += "&record_type=#{record_type}"
  $.ajax
    url: url
    type: 'post'
    success: (data) ->
      if checked_count_field_id
        $("##{checked_count_field_id}").html(data['checked_count'])
      $('.user_check_box, input[type="submit"]').prop('disabled', false)
    error: ->
      alert 'Chyba'
