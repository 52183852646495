$(document).ready ->
  $("#banners tbody").sortable({
      cursor: 'move',
      placeholder: 'placeholder',
      opacity: 0.75,
      handle: '.handle',

      helper: (e, tr) ->
        $originals = tr.children();
        $helper = tr.clone();
        $helper.children().each (index) ->
          $(this).width($originals.eq(index).width());
        return $helper

      update: (event, ui) ->
        $.ajax
          url: "/banners/change_position"
          type: "post"
          data: { id: ui.item[0].dataset.id, position: ui.item[0].rowIndex}
          success: (data) ->
          error: ->
            alert "Changes were not saved."
    });
