window.fill_name_day_line = ->
  $(document).on 'change', '#article_publish_at', ->
    date = $(this).val()
    name_day_line = $('#article_name_day_line')
    if date
      name_day_line.prop('disabled', true)
      $.ajax
        url: "/articles/name_day_line?date=#{date}"
        type: "get"
        success: (data) ->
          name_day_line.val(data)
          name_day_line.prop('disabled', false)
        error: ->
          alert 'Chyba'
    else
      name_day_line.val('')

