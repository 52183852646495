show_content_from_url = (a, target) ->
  indicator = target.siblings('.contract_tab_indicator')
  target.css('height', target.height()+'px')
  target.html(indicator.html())
  $.ajax
    url: a.attr('href')
    type: 'get'
    data: {}
    success: (data) ->
      target.html(data)
      target.css('height', '')
      target.show()
    error: ->
      alert "Chyba"

window.contract_tabs = ->
  $(document).on 'click', '.contract_tabs .tabs a', (event) ->
    event.preventDefault()
    a = $(this)
    a.parents('ul').find('li').removeClass('current')
    a.parents('li').addClass('current')
    target = a.parents('.contract_tabs').siblings('.contract_tab_content')
    show_content_from_url(a, target)

  $(document).on 'click', '.contract_tab_content .pagination .pagination a', (event) ->
    event.preventDefault();
    a = $(this)
    target = a.parents('.contract_tab_content')
    show_content_from_url(a, target)

window.show_contract_payment_split = ->
  $(document).on 'click', '.show_split', (event) ->
    td_id = $(this).data('td-id')
    $(this).parents('td').hide()
    $("##{td_id}").show()
