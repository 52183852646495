webinar_ping = ->
  webinar_uid = $('#webinar_uid').html()
  $.ajax
    url: "/webinars/#{webinar_uid}/ping"
    type: 'post'
    data: {}
    success: (data) ->
      if data['redirect']
        document.location = data['redirect']

window.repeat_webinar_ping = ->
  webinar_ping()
  setInterval(webinar_ping, 300000)

