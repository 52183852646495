$(document).on 'click', '.remove_to_move_stock_product', (event) ->
  event.preventDefault()
  $(this).parents('form').find('.client_shipment_value').html('?')
  $(this).parents('form').find('.client_shipment_weight').html('?')
  id = '#' + $(this).attr('id').replace('remove_', '')
  $(id).remove()

$(document).on 'change', '.shipment_kind', (event) ->
  select = $(this)
  form_elements = select.parents('form').find('input, select')
  form_elements.prop('disabled', true)
  post_to_client_id = select.data('post-to-client-id')
  post_to_dealer_id = select.data('post-to-dealer-id')
  if post_to_client_id
    url = "/post_to_clients/#{post_to_client_id}/update_shipment_kind"
  else
    url = "/post_to_dealers/#{post_to_dealer_id}/update_shipment_kind"
  shipment_kind = select.val()
  $.ajax
    url: url
    type: 'patch'
    data: {shipment_kind: shipment_kind}
    success: (data) ->
      form_elements.prop('disabled', false)
    error: ->
      alert 'Chyba'

