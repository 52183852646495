$(document).ready ->
  $(document).on 'keyup', '.final_prices', ->
    update_total_and_checks_from_final_prices()

  $(document).on 'click', '#suggest_prices_button', ->
    event.preventDefault()
    $('.final_price_suggestion').each ->
      id = '#' + $(this).attr('id').replace('_suggestion', '')
      $(id).val($(this).val())
    update_total_and_checks_from_final_prices()

  $(document).on 'click', '#suggest_current_prices_button', ->
    event.preventDefault()
    $('.final_price_suggestion_current').each ->
      id = '#' + $(this).attr('id').replace('_suggestion_current', '')
      $(id).val($(this).val())
    update_total_and_checks_from_final_prices()

window.update_total_and_checks_from_final_prices = ->
  sum = 0
  $('.final_prices').each ->
    val = $(this).val().replace(/,/, '.').replace(' ', '')
    num = parseFloat(val)
    amount = parseInt($(this).parent().parent().children('.amount').html().replace(/&nbsp;/g, ''))
    if !isNaN(num)
      update_check_amount($(this).attr('id'), num)
    if $(this).hasClass('add_vat')
      vat_multiplier = parseFloat($('#vat_multiplier').html())
      nominal_value = parseFloat($(this).parent().children('.nominal_value').val())
      num = (num-nominal_value)*(vat_multiplier-1) + num
    sum += amount * Number(num).toFixed($('#rounding').html())
  shipping = parseFloat($('#shipping').html().replace(/,/, '.').replace(' ', ''))
  sum += shipping
  sum = Number(sum).toFixed($('#rounding').html())
  $('#order_total').val(sum)

window.update_check_amount = (final_price_field_id, value) ->
  check_id = '#' + final_price_field_id.replace('final_price', 'final_price_check')
  minimal_profit_id = '#' + final_price_field_id.replace('final_price', 'final_price_minimal_profit')
  points_id = '#' + final_price_field_id.replace('final_price', 'final_price_points')
  check_amount = value - $(minimal_profit_id).val() - $(points_id).val() * $('#max_point_price').html()
  check_amount = Math.round(check_amount)
  $(check_id).html('(' + check_amount + ')')
  purchase_price = parseFloat($('#' + final_price_field_id.replace('final', 'purchase')).val())
  if !isNaN(purchase_price) && check_amount < purchase_price
    $(check_id).addClass('red')
  else if !isNaN(purchase_price)
    $(check_id).removeClass('red')

window.check_for_final_prices = ->
  empty = 0
  $('.final_prices').each ->
    if $(this).val() == ''
      empty += 1
  status = $('#order_status option:selected').val()
  if empty > 0 && status != "4"
    alert($('#final_prices_missing_text').html())
    false
  else
    true

