change_timeline = ->
  date = $('#hand_over_appointment_date').val()
  timeline = $('#hand_overs_timeline')
  ffc_id = $('#hand_over_appointment_ffc_id').val()
  id = $('#hand_over_appointment_id').val()
  handover_person_type = $('input[name="hand_over_appointment[handover_person_type]"]:checked').val()
  dealer_name = $('#dealer_name').val()
  if date != '' && ffc_id != ''
    size = if timeline.data('fixedHandoverLength') == 1 then 1 else checked_order_ids().length
    timeline.html('...')
    pieces = 0
    $('.hand_over_appointment_order_product_amount').each ->
      pieces += parseInt($(this).val()) or 0
    $.get '/hand_over_appointments/timeline', {date: date, ffc_id: ffc_id, size: size, handover_person_type: handover_person_type, dealer_name: dealer_name, hand_over_appointment_id: id, pieces: pieces},
          (data) ->
            timeline.html(data)
            timeline.show()
   else
    timeline.html('')

window.handle_timeline_change = ->
  $(document).on 'change', '#hand_over_appointment_date', ->
    change_timeline()
    update_hand_over_at(true)

window.handle_timeline_click = ->
  $(document).on 'click', '#timeline div.available', (event) ->
    event.preventDefault()
    [hours, mins] = $(this).data('starts-at').split(':')
    $('#hand_over_appointment_hand_over_at_4i').val(hours)
    $('#hand_over_appointment_hand_over_at_5i').val(mins)

checked_order_ids = ->
  order_ids = []
  for order_checkbox in $('#hand_over_appointment_orders input.order_checkbox[type=checkbox]:checked')
    order_ids.push($(order_checkbox).val())
  order_ids

update_orders = (client, dealer) ->
  if $('#client_name').val() == undefined
    return
  order_id = $.urlParam('order_id')
  hand_over_appointment_id = $('#hand_over_appointment_id').val()
  date = $('#hand_over_appointment_date').val()
  if client || dealer || $('input[name="hand_over_appointment[handover_person_type]"]:checked').val() == 'dealer'
    $('#hand_over_appointment_orders').hide()
    $('#hand_over_indicator').show()
    data = {client: client, dealer: dealer, order_id: order_id, hand_over_appointment_id: hand_over_appointment_id, date: date}
    if window.location.search
      parsed_params = new URLSearchParams(window.location.search)
      if parsed_params.get('all_orders')
        data['all_orders'] = parsed_params.get('all_orders')
    $.get '/hand_over_appointments/update_orders',
          data,
          (data) ->
            $('#hand_over_indicator').hide()
            $('#hand_over_appointment_orders').html(data)
            $('#hand_over_appointment_orders').show()
  else
    $('#hand_over_appointment_orders').html('')

window.update_orders_for_dealer_name = (event, ui) ->
  dealer_name = ui.item.value
  $('.dealer_name').val(dealer_name)
  if dealer_name.match(/(\d+)\)/)
    update_orders(null, dealer_name)

window.update_orders_for_client_name = (event, ui) ->
  client_name = ui.item.value
  $('.client_name').val(client_name)
  if client_name.match(/(\d+)\)/)
    update_orders(client_name, null)

window.toggle_client_or_dealer_name_rows = (skip_update_orders = false)->
  $(document).on 'click', '.handover_person_type', ->
    $('.handover_options_row').hide()
    val = $('input[name="hand_over_appointment[handover_person_type]"]:checked').val()
    $("#handover_options_row_#{val}").show()
    if $('input[name="hand_over_appointment[handover_person_type]"]:checked').val() == 'dealer'
      previous_value = $('.client_name').val()
      $('.client_name').data('previous-value', previous_value)
      $('.client_name').val('')
    else if $('.client_name').val() == ''
      previous_value = $('.client_name').data('previous-value')
      $('.client_name').val(previous_value)
    if !skip_update_orders
      update_orders($('#client_name').val(), $('#dealer_name').val())
      update_hand_over_at(true)

update_hand_over_at = (use_date) ->
  ffc_id = $('#hand_over_appointment_ffc_id').val()
  hand_over_at = $('#hand_over_at')
  hand_over_at.find('input').prop('disabled', 'disabled')
  date = if use_date then $('#hand_over_appointment_date').val() else null
  id = $('#hand_over_appointment_id').val()
  timeline = $('#hand_overs_timeline')
  timeline.html('...')
  $('input[type="submit"]').prop('disabled', true)
  order_ids = checked_order_ids()
  handover_person_type = $('input[name="hand_over_appointment[handover_person_type]"]:checked').val()
  dealer_name = $('#dealer_name').val()
  if ffc_id != ''
    $.get '/hand_over_appointments/hand_over_at', {ffc_id: ffc_id, order_ids: order_ids.join(','), handover_person_type: handover_person_type, dealer_name: dealer_name, date: date, hand_over_appointment_id: id},
          (data) ->
            hand_over_at.html(data)
            $('.date').datepicker({beforeShowDay: decideDateShown})
            hand_over_at.show()
            $('input[type="submit"]').prop('disabled', false)
            change_timeline()

update_business_hours = ->
  ffc_id = $('#hand_over_appointment_ffc_id').val()
  business_hours = $('#hand_over_appointment_business_hours')
  business_hours.html('')
  if ffc_id != ''
    $.get '/hand_over_appointments/business_hours', {ffc_id: ffc_id},
          (data) ->
            business_hours.replaceWith(data)
  else
    business_hours.html(business_hours.data('notice'))

window.handle_hand_over_appointment_ffc_id_change = ->
  $(document).on 'change', '#hand_over_appointment_ffc_id', ->
      update_hand_over_at(false)
      update_business_hours()

window.submit_ffc_for_hand_over_appointments_change = ->
  $(document).on 'change', '#ffc_for_hand_over_appointments', ->
    submit_form_of($(this))

window.handle_order_checkbox = (val) ->
  $(document).on 'change', '#hand_over_appointment_orders .order_checkbox', ->
    change_timeline()
