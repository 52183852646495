window.status_form_submission = ->
  $(document).on 'change', '.status_form_radio', ->
    radio = $(this)
    status = radio.val()
    client_id = radio.data('client-id')
    if status
      radio.parents('td').find('input').attr('disabled', 'disabled')
      $.ajax
        url: "/no_contract_clients/#{client_id}.js"
        type: "post"
        dataType : 'html'
        data: { _method: 'patch', no_contract_client: {status_of_lead: status} }
        success: (data) ->
          radio.parents('td').html(data)
        error: ->
          alert 'Chyba'

tag_selected = (e, ui) ->
  e.preventDefault()
  tags = $(this).val().split(/[ ,]/)
  tags.splice(tags.length - 1, 1)
  value = tags.join(' ') + ' ' + ui.item.value
  $(this).val(value)

window.tags_suggest = (element_id, url_prefix) ->
  $(element_id).autocomplete
    source: url_prefix + '/suggest_tag',
    select: tag_selected
    focus: tag_selected


window.mark_and_delete = ->
  $(document).on 'change', '.marked', ->
    marked_count = parseInt($('#marked_count').html()) || 0
    if $(this).is(':checked')
      marked_count += 1
    else
      marked_count -= 1
    if marked_count > 0
      $('#delete_link').show()
      $('#email_filtered').show()
    else
      $('#delete_link').hide()
      $('#email_filtered').hide()

window.update_no_contract_client_vocative_note = ->
  placeholder = $('#no_contract_client_vocative').attr('placeholder')
  vocative = $('#no_contract_client_vocative').val()
  if (!!placeholder && !vocative)
    $('#no_contract_client_vocative').parents('li').find('span.vocative').show()
  else
    $('#no_contract_client_vocative').parents('li').find('span.vocative').hide()

window.suggest_no_contract_client_vocative = ->
  if (!$('#no_contract_client_vocative').val() && !!$('#no_contract_client_last_name').val() && !!$('#no_contract_client_gender').val())
    url = '/vocatives/suggest'
    params =
      name:  $('#no_contract_client_last_name').val()
      gender: $('#no_contract_client_gender').val()

    $.get url, params, (data, response) ->
      $('#no_contract_client_vocative').attr('placeholder', data['vocative'])
      update_no_contract_client_vocative_note()

window.show_hide_referral_fields_in_filter = ->
  $(document).on 'change', '#source', ->
    $('#referred_by_client').val('').hide()
    $('#recommended_by_dealer').val('').hide()
    $('#referred_by_no_contract_client').val('').hide()
    if $(this).val() == 'client'
      $('#referred_by_client').show()
    else if $(this).val() == 'recommender'
      $('#recommended_by_dealer').show()
    else if $(this).val() == 'no_contract_client'
      $('#referred_by_no_contract_client').show()

window.show_no_contract_client_hide_iban_row = ->
  $(document).on 'change', '#no_contract_client_country', ->
    if $(this).val() != 'cz'
      $('#iban_form_row').show()
    else
      $('#iban_form_row').hide()
      $('#no_contract_client_account_in_iban_format').prop('checked', false)
  $(document).on 'change', '#no_contract_client_account_in_iban_format', ->
    if $(this).is(':checked')
      $('#iban_form_row').show()
    else
      $('#iban_form_row').hide()

$(document).ready ->
  update_no_contract_client_vocative_note()

  $(document).on 'keypress', '#no_contract_client_vocative', ->
    update_no_contract_client_vocative_note()

  $(document).on 'blur', '#no_contract_client_last_name', ->
    suggest_no_contract_client_vocative()

  $(document).on 'change', '#no_contract_client_gender', ->
    suggest_no_contract_client_vocative()

  $('.no_contract_client_suggest').autocomplete({
    source: '/no_contract_client_suggests/new'
  });
