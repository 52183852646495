window.external_order_prices_autofill = ->
  $('.price').keyup ->
    if $(this).val() != ''
      price_with_vat = $("##{$(this).attr('id').replace('price', 'price_with_vat')}")

      if $(this).attr('data-vat-rate') == '0'
        price_with_vat_value = $(this).val()
      else
        vat_multiplier = parseFloat($('#vat_multiplier').html())
        price_with_vat_value = round_price(parseFloat($(this).val()) * vat_multiplier)
      price_with_vat.val(price_with_vat_value)

      product_id = $(this).attr('data-product-id')
      $(".price[data-product-id='#{product_id}']").each (index, element) =>
        if $(element).val() == ''
          $(element).val($(this).val()).trigger('change')

  $('.external-order-item-fields .amount').keyup ->
    if $(this).val() != ''
      $(this).parents('.external-order-item-fields').find('.price').attr('data-amount', $(this).val())
      $(this).parents('.external-order-item-fields').find('.price_with_vat').attr('data-amount', $(this).val()).trigger('keyup')

  $('.price, .price_with_vat, #external_order_transport_cost, #external_order_transport_cost_with_vat').keyup ->
    transport = parseInt($('#external_order_transport_cost').val()) || 0
    transport_with_vat = parseFloat($('#external_order_transport_cost_with_vat').val()) || 0
    if $('#external_order_currency').val() == 'czk'
      transport_with_vat = round(transport_with_vat, 0)
    $('#external_order_total').val(sum_of_values('.price') + transport)
    $('#external_order_total_with_vat').val(sum_of_values('.price_with_vat') + transport_with_vat)

  $('#external_order_total').keyup ->
    if $('#external_order_standalone_transport').is(':checked')
      vat_multiplier = parseFloat($('#vat_multiplier').html())
      $('#external_order_total_with_vat').val(round_price(parseFloat($(this).val()) * vat_multiplier))

  sum_of_values = (selector) ->
    sum = 0
    $(selector).each (index, element) =>
      if $(element).val() != ''
        sum += parseInt($(element).attr('data-amount')) * parseFloat($(element).val())
    sum

window.fill_vs_and_invoice_number = ->
  $('#external_order_number').keyup ->
    val = $(this).val()
    $('#external_order_variable_symbol').val(val)
    if $('#external_order_supplier_id').data('intl')
      $('#external_order_invoice_number').val(val)

window.show_cart_items_detail = ->
  $(document).on 'click', '#show_cart_items_detail', (event) ->
    event.preventDefault()
    $(this).hide()
    $('#cart_items_detail').find('.indicator').show()
    external_order_id = $(this).data('external-order-id')
    $.get "/external_orders/#{external_order_id}/cart_items_detail", (data) ->
      $('#cart_items_detail').html(data)

window.hide_sections_if_standalone_transport = ->
  $(document).on 'change', '#external_order_standalone_transport', (event) ->
    if $(this).is(':checked')
      $('#external_order_standalone_toll').attr('checked', false)
      $('#external_order_delivery_order').attr('checked', false)
      $('#external_order_delivery_order_to_switzerland').attr('checked', false)
      $('#transport_for_external_orders').show()
      $('#toll_for_external_orders').hide()
      $('#delivers_external_orders').hide()
      $('#external_order_items').hide()
      $('#external_order_delivery_order_metal').parents('li').hide()
      $('.for_standalone_toll').hide()
    else
      $('#transport_for_external_orders').hide()

window.hide_sections_if_standalone_toll = ->
  $(document).on 'change', '#external_order_standalone_toll', (event) ->
    if $(this).is(':checked')
      $('#external_order_standalone_transport').attr('checked', false)
      $('#external_order_delivery_order').attr('checked', false)
      $('#external_order_delivery_order_to_switzerland').attr('checked', false)
      $('#toll_for_external_orders').show()
      $('#transport_for_external_orders').hide()
      $('#delivers_external_orders').hide()
      $('#external_order_items').hide()
      $('#external_order_delivery_order_metal').parents('li').hide()
      $('.for_standalone_toll').show()
    else
      $('#toll_for_external_orders').hide()
      $('.for_standalone_toll').hide()

window.hide_sections_if_delivery_order = ->
  $(document).on 'change', '#external_order_delivery_order', (event) ->
    if $(this).is(':checked')
      $('#external_order_standalone_transport').attr('checked', false)
      $('#external_order_standalone_toll').attr('checked', false)
      $('#external_order_delivery_order_to_switzerland').attr('checked', false)
      $('#external_order_delivery_order_metal').parents('li').show()
      $('#delivers_external_orders').show()
      $('#external_order_items').show()
      $('#transport_for_external_orders').hide()
      $('#toll_for_external_orders').hide()
      $('.for_standalone_toll').hide()
    else
      $('#external_order_delivery_order_metal').parents('li').hide()
      $('#delivers_external_orders').hide()
      $('#external_order_items').hide()

  $(document).on 'change', '#external_order_delivery_order_to_switzerland', (event) ->
    if $(this).is(':checked')
      $('#external_order_standalone_transport').attr('checked', false)
      $('#external_order_standalone_toll').attr('checked', false)
      $('#external_order_delivery_order').attr('checked', false)
      $('#external_order_delivery_order_metal').parents('li').show()
      $('#delivers_external_orders').show()
      $('#external_order_items').show()
      $('#transport_for_external_orders').hide()
      $('#toll_for_external_orders').hide()
      $('.for_standalone_toll').hide()
    else
      $('#external_order_delivery_order_metal').parents('li').hide()
      $('#delivers_external_orders').hide()
      $('#external_order_items').hide()
