window.update_lead_vocative_note = ->
  placeholder = $('#lead_vocative').attr('placeholder')
  vocative = $('#lead_vocative').val()
  if (!!placeholder && !vocative)
    $('#lead_vocative').parents('li').find('span.vocative').show()
  else
    $('#lead_vocative').parents('li').find('span.vocative').hide()

window.suggest_lead_vocative = ->
  if (!$('#lead_vocative').val() && !!$('#lead_last_name').val() && !!$('#lead_gender').val())
    url = '/vocatives/suggest'
    params =
      name:  $('#lead_last_name').val()
      gender: $('#lead_gender').val()

    $.get url, params, (data, response) ->
      $('#lead_vocative').attr('placeholder', data['vocative'])
      update_lead_vocative_note()

$(document).ready ->
  update_lead_vocative_note()

  $(document).on 'keypress', '#lead_vocative', ->
    update_lead_vocative_note()

  $(document).on 'blur', '#lead_last_name', ->
    suggest_lead_vocative()

  $(document).on 'change', '#lead_gender', ->
    suggest_lead_vocative()
