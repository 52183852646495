window.show_recommender_fields = ->
  $('#recommender').change ->
    $('#recommender_note').toggle()
    $('#recommender_to_own_client_li').toggle()

window.fill_storing_contract_date = ->
  $('.storing_contract_signed').change ->
    date_input = $(this).closest('ol').find('.storing_contract_signed_on')
    if $(this).is(':checked') && date_input.val() == ''
      date = new Date();
      day = date.getDate();
      month =  date.getMonth() + 1;
      year = date.getFullYear();
      date_input.val(day + '.' + month + '.' + year)

window.show_storing_contract_notice = ->
  $('#client_delivery_method').change ->
    if $(this).val() == '2'
      $('.storing_contract_notice').show()
    else
      $('.storing_contract_notice').hide()

window.fill_from_no_contract = (event, ui) ->
  if ui.item && id = ui.item.value.match(/\((\d+)\)/)
    tgt = $(event.target).data('target-model')
    if !tgt
      tgt = 'client'

    $('input').prop('disabled', true)

    url = '/no_contract_clients/' + id[1] + '.json'
    $.get url, {}, (data, response) ->
      $("##{tgt}_is_company_#{data['is_company']}").prop('checked', true)
      params = ['degree_before', 'degree_after', 'gender', 'first_name', 'last_name', 'vocative', 'company', 'street',
                'city', 'zip_code', 'identification_number', 'vat_number', 'birth_date',
                'email', 'phone', 'account_number', 'iban', 'swift', 'country', 'full_phone_number']
      $.each params, (index, field) ->
        if $("input[name='#{tgt}[#{field}]']").is(':radio')
          $("input[name='#{tgt}[#{field}]']").filter("[value='#{data[field]}']").prop('checked', true)
        else
          $("##{tgt}_" + field).val(data[field])


      if (birth_date = $("##{tgt}_birth_date").val()) != ''
        $("##{tgt}_birth_date").val($.datepicker.formatDate('dd.mm.yy', new Date(birth_date)))
      unless $("##{tgt}_country").val()
        $("##{tgt}_country").val('cz')
      if $("##{tgt}_full_phone_number").length > 0
        $("##{tgt}_full_phone_number").val(data['full_phone_number'])
        $("##{tgt}_full_phone_number")[0].dispatchEvent( new Event('change'))
      recommended_params = ['referred_by_client_name', 'recommended_by_dealer_name', 'referred_by_no_contract_client_name']
      recommended = false
      $.each recommended_params, (index, field) ->
        $("##{tgt}_#{field}").val(data[field])
        recommended = recommended || !!data[field]
        true
      if recommended
        $("##{tgt}_add_recommendation").prop('checked', true)
        $("##{tgt}_add_recommendation")[0].dispatchEvent( new Event('change'))
      else
        $("##{tgt}_add_recommendation").removeAttr('checked')
        $("##{tgt}_add_recommendation")[0].dispatchEvent( new Event('change'))
    $('input').prop('disabled', false)

window.fill_from_client = (event, ui) ->
  if ui.item && id = ui.item.value.match(/.*\((\d+) - (\d+)\)/)
    $('input').prop('disabled', true)
    url = '/clients/' + id[2] + '.json'
    $.get url, {}, (data, response) ->
      tgt = $(event.target).data('target-model')
      $("##{tgt}_is_company_#{data['is_company']}").prop('checked', true);
      params = ['first_name', 'last_name', 'company', 'street',
                'city', 'zip_code', 'identification_number', 'vat_number', 'birth_date',
                'email', 'phone', 'country', 'full_phone_number']
      $.each params, (index, field) ->
        $("##{tgt}_" + field).val(data[field])
    $('input').prop('disabled', false)

window.vcard_links = ->
  $(document).on 'click', 'a.vcard, li.vcard a', (event) ->
    event.preventDefault()
    links = $(this).parent().find('.vcard_links')
    if links.size() == 0
      links = $('.vcard_links')
    cloned_links = links.clone()
    cloned_links.dialog
      autoOpen: true
      modal: true
      resizable: false
      draggable: false
      width: 300
      close: (event, ui) ->
        cloned_links.dialog('destroy')
    cloned_links.find('a').on 'click', (event) ->
      cloned_links.dialog('close')

window.update_client_vocative_note = ->
  placeholder = $('#client_vocative').attr('placeholder')
  vocative = $('#client_vocative').val()
  if (!!placeholder && !vocative)
    $('#client_vocative').parents('li').find('span.vocative').show()
  else
    $('#client_vocative').parents('li').find('span.vocative').hide()

window.suggest_client_vocative = ->
  if (!$('#client_vocative').val() && !!$('#client_last_name').val() && !!$('#client_gender').val())
    url = '/vocatives/suggest'
    params =
      name:  $('#client_last_name').val()
      gender: $('#client_gender').val()

    $.get url, params, (data, response) ->
      $('#client_vocative').attr('placeholder', data['vocative'])
      update_client_vocative_note()

window.suggest_vocative = (vocative, name, gender, fill_attribute = 'placeholder') ->
  gender_value = if gender.is(':radio') then gender.filter(':checked').val() else gender.val()
  if ((!vocative.val() || fill_attribute == 'value') && !!name.val() && !!gender_value)
    url = '/vocatives/suggest'
    params =
      name:  name.val()
      gender: gender_value
    $.get url, params, (data, response) ->
      if fill_attribute == 'value'
        vocative.val(data['vocative'])
      else
        vocative.attr(fill_attribute, data['vocative'])
      update_client_vocative_note()

window.show_client_hide_iban_row = ->
  $(document).on 'change', '#client_country', ->
    if $(this).val() != 'cz'
      $('#iban_form_row').show()
    else
      $('#iban_form_row').hide()
      $('#client_account_in_iban_format').prop('checked', false)
  $(document).on 'change', '#client_account_in_iban_format', ->
    if $(this).is(':checked')
      $('#iban_form_row').show()
    else
      $('#iban_form_row').hide()

window.overcharge_currency_change_warning = ->
  $(document).on 'submit', 'form.edit_client', (event) ->
    new_currency = $('#previous_currency').parents('li').find('.currency')
    if $('#previous_currency') && $('#previous_currency').val() != new_currency.val()
      confirmation = $('#overcharge_conversion_warning').html();
      conversion = parseFloat($("#overcharge_exchange_rate_to_#{new_currency.val()}").val())
      if conversion < 20
        conversion = 1 / conversion
      conversion = round_price(conversion)
      confirmation += ' ' + fnumber(conversion) + '. ' + $('#overcharge_conversion_ending').html()
      result = confirm(confirmation)
    else
      result = true
    if !result
      event.preventDefault()
      setTimeout ( ->
        buttons = $('input[type="submit"]')
        buttons.prop('disabled', false)
        last_button = $(buttons[buttons.size() - 1])
        buttons.val(last_button.data('reenable-to'))
      ), 500
    result

window.handle_shipping_address_different = ->
  $('#client_shipping_address_different').click ->
    if ($('#client_shipping_address_different').is(':checked'))
      $('#client_shipping_address_name').val('')
      $('#client_shipping_address_street').val('')
      $('#client_shipping_address_city').val('')
      $('#client_shipping_address_zip_code').val('')
      $('.shipping_address').show()
    else
      $('.shipping_address').hide()

window.show_client_details_different = ->
  $(document).on 'change', '#show_client_details_different', ->
    $('#client_details_different_card').toggle()

window.show_died_on = ->
  $(document).on 'change', '#client_died', ->
    $('#client_died_on').toggle()

window.feedback_smiles = ->
  $(document).on 'click', '.feedback_smile', (e) ->
    e.preventDefault()
    $('.rating').prop('checked', false)
    radio_id = $(this).data('radio-id')
    $("##{radio_id}").prop('checked', true)
    $('.feedback_smile').removeClass('bigger')
    $('.feedback_smile').addClass('greyed')
    $(this).removeClass('greyed')
    $(this).addClass('bigger')

$(document).ready ->
  update_client_vocative_note()

  $(document).on 'keypress', '#client_vocative', ->
    update_client_vocative_note()

  $(document).on 'blur', '#client_last_name', ->
    suggest_client_vocative()

  $(document).on 'change', '#client_gender', ->
    suggest_client_vocative()

  $('input[name=hand_over_to]').on 'change', ->
    if $(this).val() == 'dealer'
      $('#hand_over_to_dealer_block').show()
    else
      $('#hand_over_to_dealer_block').hide()

  $('.vocative_suggest').each ->
    vocative = $(this)
    name = $(vocative.data('name'))
    gender = $(vocative.data('gender'))
    name.on 'blur', ->
      suggest_vocative(vocative, name, gender, 'value')
    gender.on 'change', ->
      suggest_vocative(vocative, name, gender, 'value')
