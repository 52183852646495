window.load_dealer_portal_data = ->
  params = new URLSearchParams(window.location.search)
  $('.dealer_portal_content--ajax').each ->
    item = $(@)
    item.addClass('loading')
    id = item.attr('id')
    kind = id.split('__')[1]
    url = '/portal/data/'+kind+'?'+params.toString()
    item.load url, ->
      item.removeClass('loading')

window.submit_portal_filter = ->
  $(document).on 'change', '#period', ->
    $(this).parents('form').submit()

window.load_card = (card_id, dealer_id, date, div_id, additional) ->
  params = []
  for key, value of additional
    params.push encodeURIComponent(key) + '=' + encodeURIComponent(value)
  $.ajax
    url: "/portal/karta?card_id=#{card_id}&dealer_id=#{dealer_id}&date=#{date}&#{params.join('&')}"
    type: 'get'
    success: (content) ->
      $("##{div_id}").html(content)

window.submit_cards_filter = ->
  $(document).on 'change', '#date', ->
    $(this).parents('form').submit()

window.submit_cards_dealer = ->
  $(document).on 'change', '#dealer_id', ->
    $(this).parents('form').submit()

window.cards_configuration_dialog = ->
  $('#cards_configuration').dialog
    autoOpen: false
    modal: true
    resizable: false
    draggable: false
    width: 1000
    height: 500
    title: $('#cards_configuration_title').html()

window.make_cards_sortable = ->
  $("#configurable_cards").sortable({
    cursor: 'move',
    placeholder: 'placeholder',
    opacity: 0.75,
    handle: '.handle',
    update: (event, ui) ->
      $.ajax
        url: '/portal/zmenit-pozici-karty'
        type: 'post'
        data: { id: ui.item[0].dataset.id, position: ui.item.index() + 1 }
        success: (data) ->
        error: ->
          alert 'Chyba!'
  });

window.sort_card = (card_id, dealer_id, date, card_div_id) ->
  $(document).on 'click', ".sort_card_#{card_id}", (e) ->
    e.preventDefault()
    $('.sort_card').removeClass('yellow')
    $(this).addClass('yellow')
    sort_by = $(this).data('sort-by')
    load_card(card_id, dealer_id, date, card_div_id, { sort_by: sort_by })

window.toggle_user = (dealer_id, date) ->
  $(document).on 'click', '.toggle_user_on_portal_card', (e) ->
    e.preventDefault()
    card_id = $(this).parents('.portal_card').data('card-id')
    card_div_id = $(this).parents('.portal_card').attr('id')
    user_id = $(this).data('user-id')
    params = $(this).data('params')
    params['toggle_user_id'] = user_id
    load_card(card_id, dealer_id, date, card_div_id, params)

window.with_hidden = (dealer_id, date) ->
  $(document).on 'click', '.show_hidden_on_portal_card', (e) ->
    e.preventDefault()
    card_id = $(this).parents('.portal_card').data('card-id')
    card_div_id = $(this).parents('.portal_card').attr('id')
    params = $(this).data('params')
    params['with_hidden'] = if params['with_hidden'] == 'true' then 'false' else 'true'
    load_card(card_id, dealer_id, date, card_div_id, params)

window.reverse_order = (dealer_id, date) ->
  $(document).on 'click', '.reverse_order_on_portal_card', (e) ->
    e.preventDefault()
    card_id = $(this).parents('.portal_card').data('card-id')
    card_div_id = $(this).parents('.portal_card').attr('id')
    params = $(this).data('params')
    params['order_direction'] = if params['order_direction'] == 'asc' then 'desc' else 'asc'
    load_card(card_id, dealer_id, date, card_div_id, params)

window.toggle_include_buyouts = (dealer_id, date) ->
  $(document).on 'change', '#include_buyouts', (e) ->
    e.preventDefault()
    card_id = $(this).parents('.portal_card').data('card-id')
    card_div_id = $(this).parents('.portal_card').attr('id')
    params = {}
    params['include_buyouts'] = if $(this).is(':checked') then 'true' else 'false'
    load_card(card_id, dealer_id, date, card_div_id, params)
