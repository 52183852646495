window.togglePickAll = (target, ui) ->
  crypto_currency = $('.crypto_currency_select').val()
  pick_all = $(ui).prop('checked')
  if (pick_all)
    $('#'+target).val($(ui).data('disposable-amount-'+crypto_currency))
                 .prop('disabled', true)
  else
    $('#'+target).prop('disabled', false)


window.updateEstimatedFiatValue = (target, prefix) ->
  crypto_currency = $('#'+prefix+'_crypto_currency').val()
  crypto_unit = $('#'+prefix+'_crypto_unit_'+crypto_currency).val()
  fiat_currency = $('#'+prefix+'_fiat_currency').val()
  pick_all = $('#'+prefix+'_pick_all').prop('checked')

  if (pick_all)
    $('#'+prefix+'_crypto_amount')
      .val($('#'+prefix+'_pick_all').data('disposable-amount-'+crypto_currency))
      .prop('disabled', true)
  else
    $('#'+target).prop('disabled', false)

  if ($("input:radio.fiat-currency-switch:checked").length)
    fiat_currency = $("input:radio.fiat-currency-switch:checked").val()
  else
    fiat_currency = $('#'+prefix+'_fiat_currency').val()

  crypto_amount = $('#'+prefix+'_crypto_amount').val()
  if (!crypto_amount)
    return

  params = {"crypto_amount": crypto_amount, "crypto_currency": crypto_currency, "crypto_unit": crypto_unit, "fiat_currency": fiat_currency}

  $.post( "/crypto_buyouts/estimate_value.json", params, ( data ) ->
    $('#'+target).html(data['amount_human'])
  ).fail(() ->
    $('#'+target).html('')
  )
