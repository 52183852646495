$(document).ready ->
  stripDiacritics = (text)->
    text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\u0142/g, "l").replace(/[\u202F\u00A0]/, " ")
  hasText = (text, words) ->
    has = true;
    console.log(text, words)
    for word in words
      has = has && (text.toUpperCase().indexOf(word) >= 0)
    has

  window.mySearch = (params, data) ->
    params.term = params.term || '';
    if params.term.length == 0
      return data;

    term = stripDiacritics(params.term.toUpperCase());
    text = stripDiacritics(data.text.toUpperCase());
    words = term.toUpperCase().split(" ");
    result = Object.assign({}, data)
    contains_text = hasText(text, words)
    if contains_text
      return result;

    if data.children && data.children.length > 0
      if term.length > 0
        result.children = []
      for child in data.children
        child_has_text = hasText(stripDiacritics(child.text.toUpperCase()), words)
        contains_text = contains_text || child_has_text
        if child_has_text
          result.children.push(child)
    if contains_text
      return result;
    return false;

  $('.country').select2 theme: 'default', language: 'cs', matcher: mySearch
  $('.select-searchable').select2
    theme: 'default', language: 'cs', matcher: mySearch, width: 'resolve', dropdownAutoWidth: true
  $('.select-searchable-auto-height').select2
    theme: 'default', language: 'cs', matcher: mySearch, dropdownCssClass: 'auto-height'
  $('.select-taggable').select2 theme: 'default', language: 'cs', tags: true, matcher: mySearch
