window.update_ffc_orders = (event, ui) ->
  content = ui.item.value
  regexped = content.match(/(\d+)\)/);
  if regexped
    id = regexped[1]
    $('#ffc_shipment_item_orders').hide()
    $.get '/ffcs/' + $('#ffc_id').val() + '/ffc_shipment_items/update_orders', { dealer_id: id}, (data) ->
      $('#ffc_shipment_item_orders').html(data)
      $('#ffc_shipment_item_orders').show()
      $('#stock_items_value').html('0')
