window.hide_and_disable = (elems) ->
  elems.hide()
  elems.find('input, select').prop('disabled', true)

window.show_and_enable = (elems) ->
  elems.find('input, select').prop('disabled', false)
  elems.show()

window.preprocess_automatic_overcharge_transfer_form = (elem, primary_amount_kind = 'monthly_amount') ->
  form_elem = $(elem).closest('form')
  to_contract_select = $('#automatic_overcharge_transfer_to_contract_id')
  if to_contract_select.find(':selected').size() == 0
    to_contract_kind = $(to_contract_select).data('contract-kind')
  else
    to_contract_kind = $(to_contract_select.find(':selected')[0]).data('contract-kind')
  fiat_currency_option = $("#automatic_overcharge_transfer_currency").find(':selected')[0]
  frequency_type_option = $('#automatic_overcharge_transfer_frequency_type').find(':selected')[0]
  frequency_type = $('#automatic_overcharge_transfer_frequency_type').val()
  condition_type = $("input[name='automatic_overcharge_transfer[condition_type]']:checked").val()
  if(frequency_type)
    $('#payment-methods').show()
  else
    $('#payment-methods').hide()
  # Highlight overcharge box for the selected contract kind
  $('.overcharge-box').removeClass('highlight')
  $('.overcharge-box[data-contract-kind="' + to_contract_kind + '"]').addClass('highlight')

  # Make sure to display correct frequency type label
  $("#amount-kind-time-period-label").text($(frequency_type_option).data('for-what-label'))

  # Unselect options not available for the to_contract kind
  if !$($("#automatic_overcharge_transfer_condition_product_id").find(':selected')[0]).data('for-'+to_contract_kind)
    $('#automatic_overcharge_transfer_condition_product_id option').prop('selected', false)
  if !$($("#automatic_overcharge_transfer_frequency_days_of_week_value").find(':selected')[0]).data('for-'+to_contract_kind)
    $('#automatic_overcharge_transfer_frequency_days_of_week_value option').prop('selected', false)
  # Make sure to display correct options based on the to_contract kind
  if to_contract_kind == 'crypto'
    $('#automatic_overcharge_transfer_condition_product_id option[data-for-commodity="true"]').prop('disabled', true)
    $('#automatic_overcharge_transfer_condition_product_id option[data-for-crypto="true"]').prop('disabled', false)
    $('#automatic_overcharge_transfer_frequency_days_of_week_value option[data-for-commodity="true"]').prop('disabled', true)
    $('#automatic_overcharge_transfer_frequency_days_of_week_value option[data-for-crypto="true"]').prop('disabled', false)
    $('input[data-for-commodity="true"]').prop('disabled', true)
    $('input[data-for-crypto="true"]').prop('disabled', false)
    $('li[data-for-commodity="true"]').hide()
    $('li[data-for-commodity="true"] input, li[data-for-commodity="true"] select').prop('disabled', true)
    $('li[data-for-crypto="true"]').show()
    $('li[data-for-crypto="true"] input, li[data-for-crypto="true"] select').prop('disabled', false)
    $('label[data-for-commodity="true"]').hide()
    $('label[data-for-crypto="true"]').show()

    if $('#automatic_overcharge_transfer_condition_type_toggle').size() > 0
      $('.automatic_overcharge_transfer_condition_type').hide()
    else
      if condition_type == 'none'
        hide_and_disable($('#automatic_overcharge_transfer_condition_max_price'))
      else
        show_and_enable($('#automatic_overcharge_transfer_condition_max_price'))
  if to_contract_kind == 'commodity'
    $('#automatic_overcharge_transfer_condition_product_id option[data-for-crypto="true"]').prop('disabled', true)
    $('#automatic_overcharge_transfer_condition_product_id option[data-for-commodity="true"]').prop('disabled', false)
    $('#automatic_overcharge_transfer_frequency_days_of_week_value option[data-for-crypto="true"]').prop('disabled', true)
    $('#automatic_overcharge_transfer_frequency_days_of_week_value option[data-for-commodity="true"]').prop('disabled', false)
    $('input[data-for-crypto="true"]').prop('disabled', true)
    $('input[data-for-commodity="true"]').prop('disabled', false)
    $('li[data-for-crypto="true"]').hide()
    $('label[data-for-crypto="true"]').hide()
    $('label[data-for-commodity="true"]').show()
    $('li[data-for-crypto="true"] input, li[data-for-crypto="true"] select').prop('disabled', true)
    $('li[data-for-commodity="true"]').show()
    $('li[data-for-commodity="true"] input, li[data-for-commodity="true"] select').prop('disabled', false)
    hide_and_disable($('#automatic_overcharge_transfer_condition_max_price'))
    if $('#automatic_overcharge_transfer_payment_method_credorax').is(':hidden')
      $('#automatic_overcharge_transfer_payment_method_bank_account').click()
    if($('#payment-methods').find('label.checkbox:visible').length == 1)
      $('#payment-methods').hide()

  switch frequency_type
    when 'daily'
      hide_and_disable($('.automatic-overcharge-transfer-frequency-type-data'))
      show_and_enable($('.automatic-overcharge-transfer-amount-other'))
      show_and_enable($('.automatic-overcharge-transfer-amount-monthly'))
    when 'days_of_week'
      hide_and_disable($('.automatic-overcharge-transfer-frequency-type-data'))
      show_and_enable($('#automatic_overcharge_transfer_frequency_days_of_week'))
      show_and_enable($('.automatic-overcharge-transfer-amount-other'))
      show_and_enable($('.automatic-overcharge-transfer-amount-monthly'))
    when 'day_in_month'
      hide_and_disable($('.automatic-overcharge-transfer-frequency-type-data'))
      show_and_enable($('#automatic_overcharge_transfer_frequency_day_in_month'))
      $('#amount_kind_other').click()
      show_and_enable($('.automatic-overcharge-transfer-amount-other'))
      hide_and_disable($('.automatic-overcharge-transfer-amount-monthly'))

  if frequency_type
    amount_kind = $("input[name='amount_kind']:checked").val()
    switch amount_kind
      when 'other'
        hide_and_disable($('#automatic-overcharge-transfer-amount-monthly'))
        show_and_enable($('#automatic-overcharge-transfer-amount-other'))
      when 'monthly'
        hide_and_disable($('#automatic-overcharge-transfer-amount-other'))
        show_and_enable($('#automatic-overcharge-transfer-amount-monthly'))
  else
    hide_and_disable($('#automatic-overcharge-transfer-amount-other'))
    hide_and_disable($('#automatic-overcharge-transfer-amount-monthly'))

  amount = $('#automatic_overcharge_transfer_amount').val()
  monthly_amount = $('#automatic_overcharge_transfer_monthly_amount').val()
  return unless amount or monthly_amount

  req = form_elem.serialize()
  # Get the estimate
  $.post
    url: form_elem.data('summary-url')
    data: req
    success: (data) ->
      $('#automatic_overcharge_transfer_monthly_amount').val(data['monthly_amount'])
      $('#automatic_overcharge_transfer_amount').val(data['amount'])
      $('#automatic_overcharge_transfer_summary').html(data['summary'])
      $('#automatic_overcharge_transfer_summary_note').html(data['note'])
      $('#automatic_overcharge_transfer_summary_wrapper').show()
      if (data['monthly_amount_adjusted'])
        $('#automatic-overcharge-transfer-amount-monthly-adjusted').show()
      else
        $('#automatic-overcharge-transfer-amount-monthly-adjusted').hide()
    error: (data) ->
      console.debug(data)
      $('#automatic_overcharge_transfer_summary_wrapper').hide()
      $('#automatic-overcharge-transfer-amount-monthly-adjusted').hide()

window.confirm_automatic_overcharge_transfer = ->
  $(document).on 'confirm', '.automatic_overcharge_transfer', (event) ->
    previous_value = $('#automatic_overcharge_transfer_amount').data('previous-value')
    current_value = $('#automatic_overcharge_transfer_amount').val()
    if previous_value != current_value
      confirm($('#automatic_overcharge_transfer_confirm_text').html())
