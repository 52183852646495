window.format_floats = ->
  $(document).on 'keyup', '.float', ->
    number = $(this).val()
    original = number
    if number.match(/,/) || number.match(/\s/) || number.match(/[.]+/)
      number = number.replace(/,/, '.').replace(/\s/, '').replace(/[.]+/, '.')
    if number.match(/\d+[.]\d+[.]*/)
      number = number.replace(/(\d+[.]?\d+)[.]*/, '$1')
    if number.match(/[^\d.-]/)
      number = number.replace(/[^\d.-]/, '')
    if original != number
      $(this).val(number)
