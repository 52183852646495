window.reloading_background_jobs = false
check_background_job_end = ->
  monitored_jobs = $('.monitored_background_job')
  monitored_jobs.each ->
    monitored_job = $(this)
    monitored_background_job_id = monitored_job.data('id')
    $.ajax
      url: "/monitored_background_jobs/#{monitored_background_job_id}.json"
      type: 'get'
      data: {}
      success: (data) ->        
        if data['monitored_background_job']['text']
          monitored_job.find('.monitored_background_job_text').html(data['monitored_background_job']['text'])
          monitored_job.find('.monitored_background_job_progress').attr('value', data['monitored_background_job']['progress'])
        if data['monitored_background_job']['status'] == 'finished'
          monitored_job.find('.monitored_background_job_indicator').remove()
        if data['monitored_background_job']['status'] == 'finished'
          if data['monitored_background_job']['refresh_page']
            window.location.reload(true)
          else if data['monitored_background_job']['refresh_div_id']
            $.ajax
              url: data['monitored_background_job']['refresh_url']
              method: 'GET'
              success: (html) ->
                $("##{data['monitored_background_job']['refresh_div_id']}").html(html)
              error: (xhr, status, error) ->
                console.log "Error: #{error}"


window.refresh_on_background_job_end = ->
  unless window.reloading_background_jobs
    check_background_job_end()
    setInterval(check_background_job_end, 10000)
  window.reloading_background_jobs = true


window.show_queue = ->
  $(document).on 'click', '.show_queue', (event) ->
    event.preventDefault()
    queue = $(this).data('queue')
    div = $("##{queue}")
    div.show()
    div.load("/background_jobs/show_queue?queue=#{queue}")
