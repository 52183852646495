window.fill_buyout_product_price = (event, ui) ->
  content = ui.item.value
  regexped = content.match(/(\d+)\)/)
  if regexped
    id = regexped[1]
    price_field = $(this).parents('.fields').find('.price')
    item_field =  $(this).parents('.buyout_invoice_item')
    price_field.prop('disabled', true)
    $.get "/products/#{id}.json", (data) ->
      field = 'buyout_price_' + $('#buyout_invoice_currency').val()
      item_field.attr('data-buyout-item-product-value', JSON.stringify(data))
      price_field.prop('disabled', false)
      update_total()

window.update_fee_charge_on_change = ->
  $(document).on 'keyup', '.restocking_of_order, .serials, .price, .amount, .damaged_amount', (event) ->
    update_total()
    timer_holder = $('#buyout_invoice_currency')
    delay_execution timer_holder, 1000, update_fee_charge_and_total
  $(document).on 'change', '#client, #buyout_invoice_currency', (event) ->
    update_fee_charge_and_total()

window.update_buyout_trustees = ->
  $(document).on 'change', '#client', (event) ->
    load_buyout_trustees()

window.update_stock_products_transfers = ->
  $(document).on 'change', '#client', (event) ->
    load_stock_products_transfers()

window.load_stock_products_transfers = ->
  $('#buyout_invoice_stock_products_transfers').html('<i class="fa fa-refresh fa-spin"></i>')
  $.get '/buyout_invoices/stock_products_transfers', { client: $('#client').val() }, (data) ->
    $('#buyout_invoice_stock_products_transfers').html(data)

window.update_total_on_item_removal = ->
  $(document).on 'change', '.destroy_input', (event) ->
    update_total()

window.update_fee_charge_and_total = ->
  currency = $('#buyout_invoice_currency').val()
  order_ids = []
  for item in $('.restocking_of_order')
    order_ids.push($(item).val())
  order_ids = order_ids.join('|')
  serials = []
  for item in $('.serials')
    serials.push($(item).val())
  serials = serials.join('|')
  amounts = []
  for item in $('.amount')
    amounts.push($(item).val())
  amounts = amounts.join('|')
  client = $('#client').val()
  if (order_ids.length > 0 && amounts.length > 0) || client
    $.get "/buyout_invoices/update_fee_charge?client=#{client}&currency=#{currency}&order_ids=#{order_ids}&serials=#{serials}&amounts=#{amounts}", (data) ->
      $('#fee_charge').html(data)
      update_total()

window.update_total = ->
  total = 0
  for item in $('.buyout_invoice_item')
    if $(item).find('.destroy_input').val() != '1'
      price = if $(item).find('.price').val() then parseFloat($(item).find('.price').val()) else 0
      amount = if $(item).find('.amount').val() then parseInt($(item).find('.amount').val()) else 0
      total += amount * price
      if $(item).find('.damaged_amount').val()
        damaged = parseInt($(item).find('.damaged_amount').val())
        total -= damaged * window.damaged_charge[$('#buyout_invoice_currency').val()]
  fee_charge = parseFloat($('#fee_charge_value').html())
  total -= fee_charge
  $('#buyout_invoice_total').html(fnumber(total.toFixed(2)) + ' ' + $('#buyout_invoice_currency :selected').text())
  total
