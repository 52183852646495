window.refresh_btc_payment_page = ->
  setTimeout(refresh_page_if_status_changed, 5000)

window.refresh_page_if_status_changed = ->
  id = $('#bitcoin_payment_id').html()
  status = $('#bitcoin_payment_status').html()
  $.ajax
    url: "/bitcoin_payments/#{id}/status"
    type: "get"
    success: (new_status) ->
      if status != new_status
        window.location.reload()
      else
        refresh_btc_payment_page()
    error: ->
      alert 'Chyba při načtení transakce'
