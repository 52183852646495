window.toggle_blocked_commissions_visibility = ->
  $('#announcement_groups').on 'change', ->

    if $(this).val().indexOf('dealer') != -1
      $('#blocks_commissions_row').show()
    else
      $('#blocks_commissions_row').hide()

window.toggle_elearning_test_visibility = ->
  $('#announcement_completed_by').on 'change', ->
    if $(this).val() == 'test'
      $('#elearning_test_row').show()
    else
      $('#elearning_test_row').hide()

window.activate_announcement_overlay = ->
  $('.announcement_overlay__close').on 'click', ->
    $(this).parents('.announcement_overlay').remove()

