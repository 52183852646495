window.show_or_hide_radios_text_area = ->
  $('.poll-answering :radio').change ->
    $this = $(this)
    $this.closest('.poll-answerings').find('.radio-with-text-area').siblings('textarea').hide().prop('disabled', true)
    if $this.hasClass('radio-with-text-area') && $this.is(':checked')
        $this.siblings('textarea').show().prop('disabled', false)

window.show_or_hide_poll_answers = ->
  $select = $('#poll_question_kind')
  $choices = $('#choices')
  if $select.val() == 'choice'
    $choices.show()
    $(".select", $choices).hide()
  else if $select.val() == 'choice_with_text'
    $choices.show()
    $(".select", $choices).show()
  else if $select.val() == 'range' || $select.val() == 'text' || $select.val() == 'upload'
    $choices.hide()

window.show_or_hide_questionnaire_answers = ->
  $select = $('#questionnaire_question_kind')
  $choices = $('#choices')
  if $select.val() == 'choice'
    $choices.show()
    $(".select", $choices).hide()
  else if $select.val() == 'choice_with_text'
    $choices.show()
    $(".select", $choices).show()
  else if $select.val() == 'range' || $select.val() == 'text'
    $choices.hide()
