window.init_price_watch_dog_direction = ->
  $(document).on 'change', '.price_watch_dog_direction', ->
    $(this).siblings('.price_watch_dog_under').toggle()
    $(this).siblings('.price_watch_dog_over').toggle()

window.init_product_current_price = ->
  $(document).on 'change', '.product_id, .currency', ->
    fields_div = $(this).parents('.fields')
    product_id = fields_div.find('.product_id').val()
    if /.*\(\d+\)/.test(product_id)
      product_id = product_id.match(/\d+/)[0]
    currency = fields_div.find('.currency').val()
    current_price_span = fields_div.find('.current_price')
    if product_id != ''
      current_price_span.html('...')
      current_price_span.show()
      url = '/price_watch_dogs/current_product_price?product_id='+product_id+'&currency='+currency
      $.ajax
        url: url
        type: 'get'
        success: (data) ->
          current_price_span.html(data)
        error: ->
          alert 'Chyba'
    else
      current_price_span.hide()
