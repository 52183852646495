window.savings_groups_hiding = ->
  $('.programs_groups').click ->
    if $('.programs_groups.bonus').is(':checked')
      $('.pension_group').hide()
      $('.mortgage_group').hide()
      $('.commodity_account_group').hide()
      $('.crypto_group').hide()
      $('.bonus_group').show()
      $('.program_bonus').click()
    else if $('.programs_groups.pension').is(':checked')
      $('.bonus_group').hide()
      $('.mortgage_group').hide()
      $('.commodity_account_group').hide()
      $('.crypto_group').hide()
      $('.pension_group').show()
      $('.program_golden_pension').click()
    else if $('.programs_groups.mortgage').is(':checked')
      $('.bonus_group').hide()
      $('.pension_group').hide()
      $('.commodity_account_group').hide()
      $('.crypto_group').hide()
      $('.mortgage_group').show()
      $('.program_golden_mortgage').click()
    else if $('.programs_groups.commodity_account').is(':checked')
      $('.bonus_group').hide()
      $('.pension_group').hide()
      $('.mortgage_group').hide()
      $('.crypto_group').hide()
      $('.commodity_account_group').show()
      $('.program_commodity_account').click()
    else if $('.programs_groups.crypto').is(':checked')
      $('.bonus_group').hide()
      $('.pension_group').hide()
      $('.mortgage_group').hide()
      $('.commodity_account_group').hide()
      $('.crypto_group').show()
      $('.program_crypto').click()
